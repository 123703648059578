<template>
    <div>
        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
            <b-card bg-variant="bluenavy" text-variant="white" header-text-variant="white" header-bg-variant="bluenavy"
                header-tag="h2" :header="sodalizio.displayname">
                <template #header>
                    <div style="margin: -2rem -2.25rem; padding: 2rem 2.25rem">
                        <div class="row">
                            <div class="col-md-4">
                                <h2>{{ sodalizio.displayname }}</h2>
                            </div>
                            <div class="col-md-4 text-center">
                                <h2 v-if="
                                    typeof sodalizio.affiliations !== 'undefined' &&
                                    !sodalizio.force_displayname
                                ">
                                    {{ sodalizio.affiliations[0].committees.displayname }}
                                </h2>
                                <h2 v-if="
                                    typeof sodalizio.affiliations !== 'undefined' &&
                                    sodalizio.force_displayname
                                ">
                                    {{ sodalizio.force_displayname }}
                                </h2>
                            </div>
                            <div class="col-md-4 text-right">
                                <h2>COD. AFF. {{ sodalizio.id }}</h2>
                            </div>
                        </div>
                    </div>
                </template>
                <b-card-text>
                    <form class="form">
                        <div class="card-body pb-0">
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <label class="text-white font-weight-bolder font-size-h3 d-inline-block">Tipologia
                                        Tesseramento:</label>
                                    <div v-if="loaded" class="d-inline-block ml-3 font-size-h3">
                                        {{
                                            sodalizio.affiliations[0].membershiptypes.membershiptype
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Telefono:</label>
                                    <div v-if="loaded">{{ sodalizio.phonenumber }}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Cellulare:</label>
                                    <div v-if="loaded">{{ sodalizio.cellphonenumber }}</div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">E-mail:</label>
                                    <div v-if="loaded && sodalizio.email">
                                        <a class="text-success" :href="'mailto:' + sodalizio.email">{{ sodalizio.email
                                            }}</a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Codice Fiscale:</label>
                                    <div v-if="loaded">{{ sodalizio.taxcode }}</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-9">
                                    <label class="text-white font-weight-bolder font-size-h3">Indirizzo:</label>
                                    <div v-if="typeof sodalizio.address !== 'undefined'">
                                        {{ sodalizio.address.streetaddress }}
                                        -
                                        {{ sodalizio.address.zipcode }}
                                        -
                                        {{ sodalizio.address.cityname }}
                                        - ({{ sodalizio.address.provstates.provstatecode }})
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <label class="text-white font-weight-bolder font-size-h3">Partita IVA:</label>
                                    <div v-if="loaded">{{ sodalizio.vatnumber }}</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </b-card-text>
            </b-card>

            <div v-if="loaded && typeof sodalizio.rssubscription !== 'undefined'" class="mt-3 mb-3 pt-3 pb-3">
                &nbsp;
            </div>

            <div v-if="loaded && typeof sodalizio.rssubscription !== 'undefined'"
                class="alert d-flex justify-content-between align-items-center" :class="{
                    'alert-primary': [
                        'In completamento',
                        'In convalida',
                        'Integrazioni inviate',
                        'Integrazioni inviate (dopo istruttoria)',
                        'Presa in carico da un operatore',
                        'Presa in carico da un operatore (dopo istruttoria)',
                        'Convalidata in istruttoria',
                    ].includes(sodalizio.rssubscription.rssubscriptionstatuses.status),

                    'alert-warning': [
                        'Richiesta di integrazione',
                        'Richiesta di integrazione (dopo istruttoria)',
                    ].includes(sodalizio.rssubscription.rssubscriptionstatuses.status),

                    'alert-success': ['Domanda accolta'].includes(
                        sodalizio.rssubscription.rssubscriptionstatuses.status
                    ),

                    'alert-danger': [
                        'Iscrizione sospesa',
                        'Iscrizione annullata',
                        'Domanda rifiutata',
                    ].includes(sodalizio.rssubscription.rssubscriptionstatuses.status),
                }">
                <h2>
                    <small>Stato Iscrizione Registro: </small>{{ sodalizio.rssubscription.rssubscriptionstatuses.status
                    }}
                </h2>
                <button v-if="show_rssubscription" @click="show_rssubscription = false"
                    class="btn btn-primary font-weight-bolder">
                    <i class="fas fa-minus text-white"></i> Nascondi Dettagli
                </button>
                <button v-if="!show_rssubscription" @click="show_rssubscription = true"
                    class="btn btn-primary font-weight-bolder">
                    <i class="fas fa-plus text-white"></i> Mostra Dettagli
                </button>
            </div>

            <div class="p-5 m-0 border border-primary rounded" v-if="
                loaded &&
                typeof sodalizio.rssubscription !== 'undefined' &&
                show_rssubscription
            ">
                <form class="form">
                    <div class="card-body p-0 m-0">
                        <div class="form-group row">
                            <div class="col-md-12">
                                <img src="/media/logos/logo-registrosalute.png" /><br />
                                <label class="font-weight-bolder font-size-h3 d-inline-block">Data
                                    Aggiornamento:</label>
                                <span v-if="loaded" class="d-inline-block ml-3 font-size-h3">{{
                                    sodalizio.rssubscription.date
                                        ? dateFromIsoToIt(sodalizio.rssubscription.date)
                                        : "-"
                                }}</span>
                            </div>
                        </div>
                        <div v-if="sodalizio.rssubscription.note" class="form-group row">
                            <div class="col-md-12">
                                <label class="font-weight-bolder font-size-h3 d-inline-block">Note:</label>
                                <div v-if="loaded" class="ml-3">
                                    <pre style="text-wrap: wrap; font-size: 120%">{{ sodalizio.rssubscription.note }}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="mt-3 mb-3 pt-3 pb-3">&nbsp;</div>

            <b-card no-body header-bg-variant="bluenavy" header="Gestione Sodalizio" header-text-variant="white"
                header-tag="h2" border-variant="bluenavy">
                <b-tabs card justified v-model="tabIndex">
                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Organigramma"
                        :title-link-class="linkClass(0)" active>
                        <b-card-text>
                            <h6>Struttura Sodalizio</h6>
                            Se un componente del Consiglio Direttivo ricopre cariche di
                            Istruttore, Maestro, Allenatore,Tecnico, Arbitro o Medico; è
                            obbligatorio ripetere il nominativo all'interno dell'organigramma.
                        </b-card-text>

                        <SmartTable ref="consiglio" url="/sportingclub/board/index" :is_dialog="true"
                            base_url="/sportingclub/board" base_path="/sportingclub/board" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                                { name: 'isdirector', filter: '1' },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalOrganigramma">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Consiglio
                                        Direttivo</span>
                                </h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="organigramma" url="/sportingclub/board/index" :is_dialog="true"
                            base_url="/sportingclub/board" base_path="/" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                                { name: 'isdirector', filter: '0' },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalOrganigramma">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Organigramma</span>
                                </h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="storicoorganigramma" url="/sportingclub/boardhistory/index" :is_dialog="true"
                            base_url="/sportingclub/boardhistory" base_path="/" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                                { name: 'isdirector', filter: '1' },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalOrganigramma">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Storico</span>
                                </h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Documenti"
                        :title-link-class="linkClass(1)">
                        <SmartTable ref="documenti" url="/sportingclub/document/index" :is_dialog="true"
                            base_url="/sportingclub/document" base_path="/" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalDocumenti">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Documenti
                                        Sodalizio</span>
                                </h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Certificati"
                        :title-link-class="linkClass(2)">
                        <SmartTable ref="certificati" url="/sportingclub/certificate/index" :is_dialog="true"
                            base_url="/sportingclub/certificate" base_path="/sportingclub/certificate" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalCertificati">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Certificati
                                        Sodalizio</span>
                                </h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Affiliazione"
                        :title-link-class="linkClass(3)">
                        <b-card-text>
                            <h3>Stato Affiliazione Corrente</h3>

                            <div class="row">
                                <div v-if="
                                    $store.getters.currentUser.usertype !== 'Sodalizio' &&
                                    sodalizio.is_last_affiliation
                                " class="col-md-3">
                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="cambiaStatoAffiliazione">
                                        <i class="fas fa-exchange-alt"></i> Cambia Stato
                                        Affiliazione
                                    </a>
                                </div>

                                <div v-if="
                                    $store.getters.currentUser.usertype === 'Sodalizio' ||
                                    !sodalizio.is_last_affiliation
                                " class="col-md-3">
                                    <a href="#" class="mt-2 btn btn-secondary font-weight-bolder mr-3">
                                        <i class="fas fa-exchange-alt"></i> Cambia Stato
                                        Affiliazione
                                    </a>
                                </div>

                                <div v-if="
                                    ($store.getters.currentUser.usertype ===
                                        'Segreteria Nazionale' ||
                                        $store.getters.currentUser.usertype === 'Superadmin' ||
                                        $store.getters.currentUser.usertype === 'Comitato') &&
                                    sodalizio.is_last_affiliation
                                " class="col-md-3">
                                    <a href="#" @click.prevent="showCambiaDatiAffiliazioneModal"
                                        class="mt-2 btn btn-primary font-weight-bolder mr-3"><i
                                            class="fas fa-database"></i> Cambia
                                        Dati di Affiliazione
                                    </a>
                                </div>

                                <div v-if="
                                    ($store.getters.currentUser.usertype !==
                                        'Segreteria Nazionale' &&
                                        $store.getters.currentUser.usertype !== 'Superadmin' &&
                                        $store.getters.currentUser.usertype !== 'Comitato') ||
                                    !sodalizio.is_last_affiliation
                                " class="col-md-3">
                                    <a href="#" class="mt-2 btn btn-secondary font-weight-bolder mr-3"><i
                                            class="fas fa-database"></i> Cambia Dati di Affiliazione
                                    </a>
                                </div>

                                <b-modal ref="cambiaDatiAffiliazioneModal" size="lg" hide-footer
                                    title="Cambia dati di Affiliazione">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                        spinner-variant="primary">
                                        <SmartForm ref="cambiaDatiAffiliazioneForm" v-if="sodalizio.last_affiliation"
                                            base_url="/sportingclub/affiliation-change"
                                            base_path="/sportingclub/affiliation-change" form_type="edit"
                                            :redirect_after_save="false" :item_id="sodalizio.last_affiliation.id"
                                            v-on:values-saved="aggiornaCambiaDatiAffiliazione"
                                            v-on:values-loaded="aggiornaComitatoCorrente">
                                            <template v-slot:title>
                                                <a href="#" @click.prevent="closeCambiaDatiAffiliazioneModal"
                                                    class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                    <i class="fas fa-window-close icon-sm"></i> Chiudi
                                                </a>
                                            </template>
                                            <template v-slot:toolbar="slotProps">
                                                <div class="d-flex flex-wrap justify-content-end">
                                                    <a href="#" @click.prevent="slotProps.values.doAction('save')"
                                                        class="mt-2 btn btn-success font-weight-bolder">
                                                        <i class="fas fa-save"></i> Salva
                                                    </a>
                                                </div>
                                            </template>
                                            <template v-slot:footer="slotProps">
                                                <div class="card-footer border-0 py-5">
                                                    <div class="d-flex flex-wrap justify-content-between">
                                                        <a href="#" @click.prevent="closeCambiaDatiAffiliazioneModal"
                                                            class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                            <i class="fas fa-window-close icon-sm"></i> Chiudi
                                                        </a>

                                                        <a href="#" @click.prevent="slotProps.values.doAction('save')"
                                                            class="mt-2 btn btn-success font-weight-bolder">
                                                            <i class="fas fa-save"></i> Salva
                                                        </a>
                                                    </div>
                                                </div>
                                            </template>
                                        </SmartForm>
                                    </b-overlay>
                                </b-modal>

                                <div class="col-md-3">
                                    <a v-if="
                                        sodalizio.can_send_affiliation &&
                                        [
                                            'Superadmin',
                                            'Segreteria Nazionale',
                                            'Comitato',
                                        ].includes(this.$store.getters.currentUser.usertype)
                                    " @click.prevent="showInviaDatiAffiliazioneModal" href="#"
                                        class="mt-2 btn btn-primary font-weight-bolder mr-3"><i
                                            class="fas fa-share-square"></i>
                                        Invia Affiliazione
                                    </a>
                                </div>

                                <b-modal ref="inviaDatiAffiliazioneModal" size="lg" hide-footer
                                    title="Rinnovo Affiliazione">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                        spinner-variant="primary">
                                        <SmartForm ref="inviaDatiAffiliazioneForm" v-if="sodalizio.last_affiliation"
                                            base_url="/sportingclub/affiliation-send"
                                            base_path="/sportingclub/affiliation-send" form_type="edit"
                                            :redirect_after_save="false" :item_id="sodalizio.last_affiliation.id"
                                            v-on:values-saved="aggiornaInviaDatiAffiliazione">
                                            <template v-slot:title>
                                                <small class="text-dark mt-0 pt-0 text-justify mr-3">Si ricorda che nel
                                                    momento in cui si rinnova
                                                    l'affiliazione per la nuova stagione, non è più
                                                    possibile emettere certificati ed inserire/utilizzare
                                                    le tessere della stagione sportiva precedente.</small><br />
                                                <a href="#" @click.prevent="closeInviaDatiAffiliazioneModal"
                                                    class="mt-2 btn btn-danger font-weight-bolder mr-2 text-nowrap">
                                                    <i class="fas fa-window-close icon-sm"></i>&nbsp;Chiudi
                                                </a>
                                            </template>
                                            <template v-slot:toolbar="slotProps">
                                                <div class="d-flex flex-wrap justify-content-end">
                                                    <a href="#" @click.prevent="slotProps.values.doAction('save')"
                                                        class="mt-2 btn btn-success font-weight-bolder">
                                                        <i class="fas fa-paper-plane"></i> Invia Richiesta
                                                    </a>
                                                </div>
                                            </template>
                                            <template v-slot:footer="slotProps">
                                                <div class="card-footer border-0 py-5">
                                                    <div class="d-flex flex-wrap justify-content-between">
                                                        <a href="#" @click.prevent="closeInviaDatiAffiliazioneModal"
                                                            class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                            <i class="fas fa-window-close icon-sm"></i> Chiudi
                                                        </a>

                                                        <a href="#" @click.prevent="slotProps.values.doAction('save')"
                                                            class="mt-2 btn btn-success font-weight-bolder">
                                                            <i class="fas fa-paper-plane"></i> Invia Richiesta
                                                        </a>
                                                    </div>
                                                </div>
                                            </template>
                                        </SmartForm>
                                    </b-overlay>
                                </b-modal>

                                <div class="col-md-3">
                                    <a v-if="sodalizio.is_last_affiliation" :href="getApiUrl() +
                                        '/sportingclub/affiliationrequest/' +
                                        $route.params.id +
                                        '?token=' +
                                        token
                                        " target="_blank" class="mt-2 btn btn-primary font-weight-bolder mr-3"><i
                                            class="far fa-file-pdf"></i> Scarica la domanda di
                                        affiliazione
                                    </a>

                                    <a v-if="!sodalizio.is_last_affiliation" href="#"
                                        class="mt-2 btn btn-secondary font-weight-bolder mr-3"><i
                                            class="far fa-file-pdf"></i>
                                        Scarica la domanda di affiliazione
                                    </a>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div v-if="
                                    sodalizio.affiliatecurrentyear &&
                                    sodalizio.is_last_affiliation
                                " class="col-md-12 text-center text-success font-weight-bold">
                                    <h6>Il sodalizio è affiliato all'anno corrente</h6>
                                </div>
                                <div v-if="
                                    !sodalizio.affiliatecurrentyear ||
                                    !sodalizio.is_last_affiliation
                                " class="col-md-12 text-center text-danger font-weight-bold">
                                    <h6>
                                        Il sodalizio non è affiliato all'anno corrente. Contatta il
                                        Comitato per rinnovare l'affiliazione.
                                    </h6>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-md-6">Codice Affiliazione ACSI:</div>
                                <div class="col-md-6">{{ sodalizio.id }}</div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">Anno:</div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.affiliations[0].annualities.solarsannualitydisplay
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">Data Affiliazione:</div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.affiliations[0].affiliationdate
                                            ? dateFromIsoToIt(
                                                sodalizio.affiliations[0].affiliationdate
                                            )
                                            : "-"
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">Data Prima Affiliazione:</div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.affiliations[0].firstaffiliationdate
                                            ? dateFromIsoToIt(
                                                sodalizio.affiliations[0].firstaffiliationdate
                                            )
                                            : "-"
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">Stato Affiliazione:</div>
                                <div class="col-md-6 text-white" v-if="sodalizio.affiliations">
                                    <span v-if="
                                        sodalizio.affiliations[0].affiliationstates
                                            .affiliationstate === 'Accettata'
                                    " class="badge pill bg-success mb-1" style="font-size: 0.9em">ATTIVA</span>

                                    <span v-if="
                                        sodalizio.affiliations[0].affiliationstates
                                            .affiliationstate === 'In Sospeso'
                                    " class="badge pill bg-warning mb-1" style="font-size: 0.9em">SOSPESA</span>

                                    <span v-if="
                                        sodalizio.affiliations[0].affiliationstates
                                            .affiliationstate === 'Scaduta'
                                    " class="badge pill bg-gray-600 mb-1" style="font-size: 0.9em">SCADUTA</span>

                                    <span v-if="
                                        sodalizio.affiliations[0].affiliationstates
                                            .affiliationstate === 'Cessata'
                                    " class="badge pill bg-gray-800 mb-1" style="font-size: 0.9em">CESSATA</span>

                                    <span v-if="
                                        sodalizio.affiliations[0].affiliationstates
                                            .affiliationstate === 'Rifiutata'
                                    " class="badge pill bg-danger mb-1" style="font-size: 0.9em">RIFIUTATA</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">Con Somministrazione:</div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{ sodalizio.affiliations[0].foodflag ? "Si" : "No" }}
                                </div>
                            </div>

                            <div class="row" v-if="
                                ['Superadmin', 'Segreteria Nazionale'].includes(
                                    $store.getters.currentUser.usertype
                                )
                            ">
                                <div class="col-md-6">
                                    Iscrizione al REGISTRO SPORT E SALUTE inibita dalla Segreteria
                                    Nazionale?:
                                </div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.affiliations[0].coniaffiliationflagadmin
                                            ? "Si"
                                            : "No"
                                    }}
                                </div>
                            </div>

                            <div class="row" v-if="
                                ['Superadmin', 'Segreteria Nazionale'].includes(
                                    $store.getters.currentUser.usertype
                                )
                            ">
                                <div class="col-md-6">
                                    Invio del Consiglio al REGISTRO SPORT E SALUTE inibito dalla Segreteria Nazionale?:
                                </div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.affiliations[0].sendcouncilflag
                                            ? "Si"
                                            : "No"
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    Abilitata per iscrizione al REGISTRO SPORT E SALUTE?:
                                </div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.affiliations[0].coniaffiliationflag ? "Si" : "No"
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">Data Iscrizione CONI:</div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.coni_info.coniaffiliationdate
                                            ? dateFromIsoToIt(sodalizio.coni_info.coniaffiliationdate)
                                            : "n.d."
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">N. Iscrizione al CONI:</div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.coniaffiliationnumber
                                            ? sodalizio.coniaffiliationnumber
                                            : "n.d."
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    Data Iscrizione REGISTRO SPORT E SALUTE:
                                </div>
                                <div class="col-md-6" v-if="sodalizio.affiliations">
                                    {{
                                        sodalizio.rs_info.rsaffiliationdate
                                            ? dateFromIsoToIt(sodalizio.rs_info.rsaffiliationdate)
                                            : "n.d."
                                    }}
                                </div>
                            </div>
                        </b-card-text>

                        <SmartTable ref="storicoaffiliazioni" url="/sportingclub/affiliation-history/index"
                            base_path="/" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Storico
                                        Affiliazioni</span>
                                </h2>
                            </template>

                            <template v-slot:td-idcommittee="slotProps">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <span
                                                v-if="typeof slotProps.values.item['force_idcommittee'] === 'undefined'"
                                                class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                                    slotProps.values.item[slotProps.values.field.name] }}</span>

                                            <span
                                                v-if="typeof slotProps.values.item['force_idcommittee'] !== 'undefined'"
                                                class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                                    slotProps.values.item["force_idcommittee"] }}</span>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </SmartTable>

                        <b-modal ref="cambiaStatoAffiliazioneModal" size="lg" hide-footer
                            title="Cambia lo stato dell'Affiliazione">
                            <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                spinner-variant="primary">
                                <p>
                                    Stato corrente dell'Affiliazione:
                                    <strong v-if="
                                        typeof sodalizio !== 'undefined' &&
                                        typeof sodalizio.affiliations !== 'undefined' &&
                                        sodalizio.affiliations.length
                                    ">{{
                                        sodalizio.affiliations[0].affiliationstates
                                            .affiliationstate
                                    }}</strong>
                                </p>

                                <form>
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">Nuovo stato Affiliazione:</label>
                                        <div class="col-md-8">
                                            <select v-model="nuovoStatoAffiliazione" class="form-control">
                                                <option v-if="
                                                    !(
                                                        $store.getters.currentUser.usertype ===
                                                        'Comitato' &&
                                                        typeof sodalizio.affiliations !== 'undefined' &&
                                                        sodalizio.affiliations.length &&
                                                        sodalizio.affiliations[0].affiliationstates
                                                            .affiliationstate === 'Accettata'
                                                    )
                                                " value="In Sospeso">
                                                    In Sospeso
                                                </option>
                                                <option value="Accettata">Accettata</option>
                                                <option v-if="
                                                    !(
                                                        $store.getters.currentUser.usertype ===
                                                        'Comitato' &&
                                                        typeof sodalizio.affiliations !== 'undefined' &&
                                                        sodalizio.affiliations.length &&
                                                        sodalizio.affiliations[0].affiliationstates
                                                            .affiliationstate === 'Accettata'
                                                    )
                                                " value="Rifiutata">
                                                    Rifiutata
                                                </option>
                                                <option v-if="
                                                    !(
                                                        $store.getters.currentUser.usertype ===
                                                        'Comitato' &&
                                                        typeof sodalizio.affiliations !== 'undefined' &&
                                                        sodalizio.affiliations.length &&
                                                        sodalizio.affiliations[0].affiliationstates
                                                            .affiliationstate === 'Accettata'
                                                    )
                                                " value="Scaduta">
                                                    Scaduta
                                                </option>
                                                <option v-if="
                                                    !(
                                                        $store.getters.currentUser.usertype ===
                                                        'Comitato' &&
                                                        typeof sodalizio.affiliations !== 'undefined' &&
                                                        sodalizio.affiliations.length &&
                                                        sodalizio.affiliations[0].affiliationstates
                                                            .affiliationstate === 'Accettata'
                                                    )
                                                " value="Cessata">
                                                    Cessata
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </form>

                                <div class="d-flex justify-content-end">
                                    <b-button class="mt-3" variant="danger"
                                        @click.prevent="hideModalAffiliazione">Annulla</b-button>
                                    <b-button class="ml-3 mt-3" variant="success"
                                        @click.prevent="confermaCambiaStatoAffiliazione">Conferma</b-button>
                                </div>
                            </b-overlay>
                        </b-modal>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Stock Tessere"
                        :title-link-class="linkClass(4)">
                        <b-card-text>
                            <div class="row">
                                <div class="col-md-3">
                                    <h3>Gestione Tessere Sodalizio</h3>
                                    <h6>Disponibilità Tessere {{ current_year_tessere }}</h6>
                                </div>

                                <div class="col-md-3">
                                    <form>
                                        <div class="form-group">
                                            <label for="idannualitytessere">Annualità:</label>
                                            <select id="idannualitytessere" v-model="idannualitytessere"
                                                class="form-control">
                                                <option value="">Seleziona un'annualità</option>
                                                <option v-for="annuality in annualities" :value="annuality.id">
                                                    {{ annuality.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-md-6"></div>
                            </div>
                        </b-card-text>

                        <SmartTable ref="stocktessere" url="/sportingclub/membercard-range/index" base_path="/"
                            :fixed_filters="idannualitytessere
                                ? [
                                    { name: 'idsportingclub', filter: $route.params.id },
                                    { name: 'idannuality', filter: idannualitytessere },
                                ]
                                : [{ name: 'idsportingclub', filter: $route.params.id }]
                                " custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Tessere
                                        Disponibili</span>
                                </h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Tesseramenti"
                        :title-link-class="linkClass(5)">
                        <b-tabs card justified v-model="tabIndexTesseramenti">
                            <b-tab class="border border-primary border-top-0 rounded-bottom"
                                title="Ricerca Tesseramenti" :title-link-class="linkClassTesseramenti(0)" active>
                                <SmartTable ref="tesseramentiRicerca" url="/sportingclub/enrolled-user/index"
                                    :is_dialog="true" base_url="/sportingclub/enrolled-user"
                                    base_path="/sportingclub/enrolled-user" :fixed_filters="idannualitytesseramenti
                                        ? [
                                            { name: 'idsportingclub', filter: $route.params.id },
                                            {
                                                name: 'idannuality',
                                                filter: idannualitytesseramenti,
                                            },
                                            { name: 'pagetype', filter: 'sportingclub' },
                                        ]
                                        : [
                                            { name: 'idsportingclub', filter: $route.params.id },
                                            { name: 'pagetype', filter: 'sportingclub' },
                                        ]
                                        " custom_class="mx-0 px-0" v-on:close-modal="closeModalTesseramenti">
                                    <template v-slot:title>
                                        <h2 class="card-title">
                                            <span class="card-label font-weight-bolder text-dark font-size-h3">Ricerca
                                                Tesseramenti</span>
                                        </h2>
                                    </template>

                                    <template v-slot:before-filters>
                                        <div class="row">
                                            <div class="col-4">
                                                <form>
                                                    <div class="form-group">
                                                        <label for="idannualitytesseramenti">Annualità:</label>
                                                        <select id="idannualitytesseramenti"
                                                            v-model="idannualitytesseramenti" class="form-control">
                                                            <option value="">Seleziona un'annualità</option>
                                                            <option v-for="annuality in annualities"
                                                                :value="annuality.id">
                                                                {{ annuality.label }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </form>
                                            </div>

                                            <div class="col-8 text-right align-middle">
                                                <button v-if="
                                                    idannualitytesseramenti &&
                                                    idannualityaffiliation &&
                                                    idannualitytesseramenti ===
                                                    idannualityaffiliation &&
                                                    (isSuperadminOrSegreteria ||
                                                        ($store.getters.currentUser.usertype ===
                                                            'Comitato' &&
                                                            isSodalizioAttivo))
                                                " class="mt-4 btn btn-info font-weight-bolder ml-3"
                                                    @click.prevent="activateTesserati">
                                                    <i class="fas fa-star"></i> Attiva Tesserati
                                                    Selezionati
                                                </button>

                                                <button v-if="
                                                    idannualitytesseramenti &&
                                                    idannualityaffiliation &&
                                                    idannualitytesseramenti ===
                                                    idannualityaffiliation &&
                                                    isSodalizioAttivo
                                                " class="mt-4 btn btn-primary font-weight-bolder ml-3"
                                                    @click.prevent="sendEmailApp">
                                                    <i class="fas fa-paper-plane"></i> Invia E-mail di
                                                    attivazione App
                                                </button>

                                                <button v-if="
                                                    can_migrate &&
                                                    idannualitytesseramenti &&
                                                    idannualityaffiliation &&
                                                    idannualitytesseramenti < idannualityaffiliation &&
                                                    isSodalizioAttivo
                                                " class="mt-4 btn btn-info font-weight-bolder ml-3"
                                                    @click.prevent="migrateTesserati">
                                                    <i class="fas fa-fast-forward"></i> Migra Tesserati
                                                    Selezionati
                                                </button>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:td-idenrolledstate="slotProps">
                                        <td v-if="slotProps.values.field.is_visible_for_index">
                                            <div
                                                class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                                <span v-if="
                                                    slotProps.values.item.idenrolledstate === 'Attivo'
                                                " class="badge pill bg-success mb-1"
                                                    style="font-size: 0.9em">ATTIVO</span>

                                                <span v-if="
                                                    slotProps.values.item.idenrolledstate ===
                                                    'Eliminato'
                                                " class="badge pill bg-danger mb-1"
                                                    style="font-size: 0.9em">ELIMINATO</span>

                                                <span v-if="
                                                    slotProps.values.item.idenrolledstate === 'In Bozza'
                                                " class="badge pill bg-info mb-1" style="font-size: 0.9em">IN
                                                    BOZZA</span>

                                                <span v-if="
                                                    slotProps.values.item.idenrolledstate ===
                                                    'Inserito - Da Attivare'
                                                " class="badge pill bg-warning mb-1" style="font-size: 0.9em">INSERITO
                                                    - DA ATTIVARE</span>

                                                <span v-if="
                                                    slotProps.values.item.idenrolledstate ===
                                                    'Terminato'
                                                " class="badge pill bg-dark mb-1"
                                                    style="font-size: 0.9em">SCADUTO</span>

                                                <span v-if="slotProps.values.item.is_in_edit_state === true"
                                                    class="badge pill bg-primary mb-1" style="font-size: 0.9em">MODIFICA
                                                    IN SOSPESO</span>

                                                <span v-if="
                                                    slotProps.values.item.coniaffiliationdate &&
                                                    slotProps.values.item.coniaffiliationdate !== '-'
                                                " class="badge pill bg-primary mb-1" style="font-size: 0.9em"><i
                                                        class="fas fa-satellite-dish text-white"></i>
                                                    INVIATO AL CONI</span>

                                                <span v-if="
                                                    slotProps.values.item.rsaffiliationdate &&
                                                    slotProps.values.item.rsaffiliationdate !== '-'
                                                " class="badge pill bg-success mb-1" style="font-size: 0.9em"><i
                                                        class="fas fa-satellite-dish text-white"></i>
                                                    INVIATO AL REGISTRO</span>

                                                <span
                                                    v-if="(!slotProps.values.item.rsaffiliationdate || slotProps.values.item.rsaffiliationdate === '-') && typeof slotProps.values.item.rsaffiliationprevsendedyear !== 'undefined' && slotProps.values.item.rsaffiliationprevsendedyear"
                                                    class="badge pill bg-primary mb-1"
                                                    style="font-size: 0.9em; text-transform: uppercase; text-align: left; text-wrap: wrap; max-width: 400px;"><i
                                                        class="fas fa-satellite-dish text-white"></i> TESSERAMENTO {{
                                                            slotProps.values.item.rsaffiliationprevsendedyear }}
                                                    GIÀ<br />TRASMESSO AL REGISTRO</span>

                                                <span v-if="
                                                    typeof slotProps.values.item.istaxcodeerrorflag !==
                                                    'undefined' &&
                                                    slotProps.values.item.istaxcodeerrorflag === 'Si'
                                                " class="badge pill bg-danger mb-1" style="font-size: 0.9em"><i
                                                        class="fas fa-window-close text-white"></i>
                                                    ANOMALIA CF</span>

                                                <span v-if="
                                                    typeof slotProps.values.item
                                                        .isconi2activityerrorflag !== 'undefined' &&
                                                    slotProps.values.item.isconi2activityerrorflag ===
                                                    'Si'
                                                " class="badge pill bg-danger mb-1" style="font-size: 0.9em"><i
                                                        class="fas fa-window-close text-white"></i>
                                                    ANOMALIA DISCIPLINE</span>
                                            </div>
                                        </td>
                                    </template>

                                    <template v-slot:item-actions="slotProps">
                                        <td class="text-nowrap pr-0 text-right">
                                            <a v-if="
                                                slotProps.values.item.can_be_modified &&
                                                isSodalizioAttivo
                                            " href="#" @click.prevent="
                                                slotProps.values.doItemAction(
                                                    { name: 'edit', label: 'Modifica', url: 'edit' },
                                                    slotProps.values.item.id
                                                )
                                                " class="btn btn-icon btn-circle btn-sm btn-primary mr-3"
                                                title="Modifica" alt="Modifica">
                                                <i class="fas fa-pencil-alt"></i>
                                            </a>

                                            <a v-if="
                                                slotProps.values.item.can_be_deleted &&
                                                isSuperadminOrSegreteria
                                            " href="#" @click.prevent="
                                                showDeleteEnrolledUserModal(slotProps.values.item.id)
                                                " class="btn btn-icon btn-circle btn-sm btn-danger mr-3"
                                                title="Elimina" alt="Elimina">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>

                                            <a href="#" @click.prevent="
                                                slotProps.values.doItemAction(
                                                    { name: 'view', label: 'Dettagli', url: 'view' },
                                                    slotProps.values.item.id
                                                )
                                                " class="btn btn-icon btn-circle btn-sm btn-success mr-3"
                                                title="Visualizza" alt="Visualizza">
                                                <i class="fas fa-eye"></i>
                                            </a>

                                            <a v-if="slotProps.values.item.can_view_pdf"
                                                class="btn btn-icon btn-circle btn-sm btn-info mr-3"
                                                title="Visualizza Tessera" alt="Visualizza Tessera" :href="getApiUrl() +
                                                    '/enrolled-user/pdf-card/' +
                                                    slotProps.values.item.id +
                                                    '?token=' +
                                                    token
                                                    " target="_blank">
                                                <i class="fas fa-id-card"></i>
                                            </a>

                                            <a v-if="
                                                slotProps.values.item.idenrolledstate ===
                                                'Inserito - Da Attivare' &&
                                                $store.getters.currentUser.usertype !== 'Sodalizio' &&
                                                isSodalizioAttivo
                                            " href="#" @click.prevent="
                                                attivaTesserato(slotProps.values.item.id)
                                                " class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Attiva"
                                                alt="Attiva">
                                                <i class="fas fa-star"></i>
                                            </a>

                                            <a v-if="
                                                slotProps.values.item.can_handle_insurance &&
                                                (isSuperadminOrSegreteria ||
                                                    ($store.getters.currentUser.usertype ===
                                                        'Comitato' &&
                                                        isSodalizioAttivo))
                                            " href="#" @click.prevent="
                                                showHandleInsuranceEnrolledUser(
                                                    slotProps.values.item.id
                                                )
                                                " class="btn btn-icon btn-circle btn-sm btn-warning mr-3"
                                                title="Gestione Assicurazioni" alt="Gestione Assicurazioni">
                                                <i class="fas fa-suitcase"></i>
                                            </a>

                                            <a v-if="
                                                slotProps.values.item.can_be_migrated &&
                                                isSodalizioAttivo
                                            " href="#" @click.prevent="
                                                migraTesserato(slotProps.values.item.id)
                                                " class="btn btn-icon btn-circle btn-sm btn-info mr-3"
                                                title="Migra Tesserato" alt="Migra Tesserato">
                                                <i class="fas fa-fast-forward"></i>
                                            </a>
                                        </td>
                                    </template>

                                    <template v-slot:after-nav>
                                        <div>
                                            <button v-if="
                                                idannualitytesseramenti &&
                                                idannualityaffiliation &&
                                                idannualitytesseramenti === idannualityaffiliation &&
                                                (isSuperadminOrSegreteria ||
                                                    ($store.getters.currentUser.usertype ===
                                                        'Comitato' &&
                                                        isSodalizioAttivo))
                                            " class="mt-4 btn btn-info font-weight-bolder mr-3"
                                                @click.prevent="activateTesserati">
                                                <i class="fas fa-star"></i> Attiva Tesserati Selezionati
                                            </button>

                                            <button v-if="
                                                idannualitytesseramenti &&
                                                idannualityaffiliation &&
                                                idannualitytesseramenti === idannualityaffiliation &&
                                                isSodalizioAttivo
                                            " class="mt-4 btn btn-primary font-weight-bolder mr-3"
                                                @click.prevent="sendEmailApp">
                                                <i class="fas fa-paper-plane"></i> Invia E-mail di
                                                attivazione App
                                            </button>

                                            <button v-if="
                                                can_migrate &&
                                                idannualitytesseramenti &&
                                                idannualityaffiliation &&
                                                idannualitytesseramenti < idannualityaffiliation &&
                                                isSodalizioAttivo
                                            " class="mt-4 btn btn-info font-weight-bolder mr-3"
                                                @click.prevent="migrateTesserati">
                                                <i class="fas fa-fast-forward"></i> Migra Tesserati
                                                Selezionati
                                            </button>
                                        </div>
                                    </template>
                                </SmartTable>

                                <b-modal ref="deleteEnrolledUserModal" size="xl" hide-footer title="Elimina Tesserato">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                        spinner-variant="primary">
                                        <SmartForm ref="deleteEnrolledUserForm" base_url="/sportingclub/enrolled-user"
                                            base_path="/sportingclub/enrolled-user" form_type="delete"
                                            :redirect_after_save="false" :item_id="iddeleteenrolleduser">
                                            <template v-slot:title>
                                                <a href="#" @click.prevent="closeDeleteEnrolledUserModal"
                                                    class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                    <i class="fas fa-window-close icon-sm"></i> Chiudi
                                                </a>
                                            </template>
                                            <template v-slot:toolbar="slotProps">
                                                <div class="d-flex flex-wrap justify-content-end">
                                                    <a href="#" @click.prevent="
                                                        confirmDeleteEnrolledUser(slotProps.values.id)
                                                        " class="mt-2 btn btn-danger font-weight-bolder">
                                                        <i class="fas fa-trash-alt"></i> Conferma
                                                        Eliminazione
                                                    </a>
                                                </div>
                                            </template>
                                            <template v-slot:footer="slotProps">
                                                <div class="card-footer border-0 py-5">
                                                    <div class="d-flex flex-wrap justify-content-between">
                                                        <a href="#" @click.prevent="closeDeleteEnrolledUserModal"
                                                            class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                            <i class="fas fa-window-close icon-sm"></i> Chiudi
                                                        </a>

                                                        <a href="#" @click.prevent="
                                                            confirmDeleteEnrolledUser(slotProps.values.id)
                                                            " class="mt-2 btn btn-danger font-weight-bolder">
                                                            <i class="fas fa-trash-alt"></i> Conferma
                                                            Eliminazione
                                                        </a>
                                                    </div>
                                                </div>
                                            </template>
                                        </SmartForm>
                                    </b-overlay>
                                </b-modal>

                                <b-modal ref="handleInsuranceEnrolledUser" size="xl" hide-footer
                                    title="Gestione Assicurazioni Tesserato" centered>
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                        spinner-variant="primary">
                                        <EnrolledUserInsurance :idenrolleduser="idinsuranceenrolleduser">
                                        </EnrolledUserInsurance>
                                        <div class="d-flex justify-content-end">
                                            <b-button class="mt-3" variant="danger"
                                                @click.prevent="hideHandleInsuranceEnrolledUser">Chiudi</b-button>
                                        </div>
                                    </b-overlay>
                                </b-modal>

                                <b-modal ref="migraTesseratiModal" size="fullscreen" hide-footer
                                    title="Migra Tesserati">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                        spinner-variant="primary">
                                        <div class="d-flex justify-content-between">
                                            <button v-if="migraTesserati.length" type="button" class="btn btn-success"
                                                @click.prevent="saveMigraTesserati">
                                                <i class="fas fa-save"></i> Salva
                                            </button>

                                            <button type="button" class="btn btn-danger"
                                                @click.prevent="closeMigraTesseratiModal">
                                                <i class="fas fa-window-close"></i> Chiudi
                                            </button>
                                        </div>

                                        <div class="table-responsive mt-3" style="min-height: 600px">
                                            <table
                                                class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered"
                                                style="min-height: 400px">
                                                <thead>
                                                    <tr>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Tessera</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Cognome</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Nome</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Codice Fiscale</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Qualifica</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">D. Abile</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">E-mail</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Cellulare</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">D. E. C. Medico</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Assicurazione</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Discipline CONI</span>
                                                        </th>
                                                        <th class="bg-primary">
                                                            <span class="text-white">Discipline ACSI</span>
                                                        </th>
                                                        <th class="bg-primary text-center pr-0">
                                                            <span class="text-white">Azioni</span>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr v-if="migraTesserati.length"
                                                        v-for="(item, indexTesserato) in migraTesserati">
                                                        <td>
                                                            <multiselect style="width: 100%; min-width: 300px"
                                                                v-model="item.idmembercard"
                                                                :options="optionsMembercards" :multiple="false"
                                                                :close-on-select="true" :clear-on-select="false"
                                                                placeholder="Seleziona una opzione" label="label"
                                                                track-by="id" :allow-empty="false" selectLabel=""
                                                                selectedLabel="" deselectLabel="" showLabels="">
                                                                <template v-slot:noOptions>
                                                                    Nessuna opzione disponibile
                                                                </template>
                                                            </multiselect>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'idmembercard'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "idmembercard"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="border border-primary" type="text"
                                                                v-model="item.surname" />
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'surname'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "surname"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="border border-primary" type="text"
                                                                v-model="item.name" />
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'name'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato]["name"]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="border border-primary mr-2" type="text"
                                                                v-model="item.taxcode" />
                                                            <TaxCodeBtn :modalid="'tesserato-taxcode-' + indexTesserato"
                                                                v-model="item.taxcode"></TaxCodeBtn>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'taxcode'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "taxcode"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <multiselect style="width: 100%; min-width: 300px"
                                                                v-model="item.idroletype" :options="optionsRoleTypes"
                                                                :multiple="false" :close-on-select="true"
                                                                :clear-on-select="false"
                                                                placeholder="Seleziona una opzione" label="label"
                                                                track-by="id" :allow-empty="false" selectLabel=""
                                                                selectedLabel="" deselectLabel="" showLabels="">
                                                                <template v-slot:noOptions>
                                                                    Nessuna opzione disponibile
                                                                </template>
                                                            </multiselect>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'idroletype'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "idroletype"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <b-form-radio-group v-model="item.disability" :options="[
                                                                { text: 'Si', value: 1 },
                                                                { text: 'No', value: 0 },
                                                            ]">
                                                            </b-form-radio-group>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'disability'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "disability"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="border border-primary" type="text"
                                                                v-model="item.email" />
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'email'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato]["email"]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="border border-primary" type="text"
                                                                v-model="item.phonenumber" />
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'phonenumber'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "phonenumber"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <date-picker v-model="item.certificatedate" type="date"
                                                                placeholder="Seleziona una data" format="DD/MM/YYYY"
                                                                time-title-format="DD/MM/YYYY" value-type="YYYY-MM-DD"
                                                                input-class="mx-input"
                                                                :shortcuts="date_shortcuts"></date-picker>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'certificatedate'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "certificatedate"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <multiselect style="width: 100%; min-width: 300px"
                                                                v-model="item.idinsurrance" :options="optionsInsurances"
                                                                :multiple="false" :close-on-select="true"
                                                                :clear-on-select="false"
                                                                placeholder="Seleziona una opzione" label="label"
                                                                track-by="id" :allow-empty="false" selectLabel=""
                                                                selectedLabel="" deselectLabel="" showLabels="">
                                                                <template v-slot:noOptions>
                                                                    Nessuna opzione disponibile
                                                                </template>
                                                            </multiselect>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'idinsurance'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "idinsurance"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <multiselect style="width: 100%; min-width: 300px"
                                                                v-model="item.coni2activities"
                                                                :options="optionsConi2Activities" :multiple="true"
                                                                :close-on-select="false" :clear-on-select="false"
                                                                placeholder="Seleziona una opzione" label="label"
                                                                track-by="id" :allow-empty="false" selectLabel=""
                                                                selectedLabel="" deselectLabel="" showLabels="">
                                                                <template v-slot:noOptions>
                                                                    Nessuna opzione disponibile
                                                                </template>
                                                            </multiselect>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'coni2activities'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "coni2activities"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <multiselect style="width: 100%; min-width: 300px"
                                                                v-model="item.activities" :options="optionsActivities"
                                                                :multiple="true" :close-on-select="false"
                                                                :clear-on-select="false"
                                                                placeholder="Seleziona una opzione" label="label"
                                                                track-by="id" :allow-empty="false" selectLabel=""
                                                                selectedLabel="" deselectLabel="" showLabels="">
                                                                <template v-slot:noOptions>
                                                                    Nessuna opzione disponibile
                                                                </template>
                                                            </multiselect>
                                                            <div v-if="
                                                                typeof migraTesseratiErrors[
                                                                indexTesserato
                                                                ] !== 'undefined' &&
                                                                migraTesseratiErrors[indexTesserato][
                                                                'activities'
                                                                ] !== 'undefined'
                                                            " class="text-danger">
                                                                {{
                                                                    migraTesseratiErrors[indexTesserato][
                                                                    "activities"
                                                                    ]
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button type="button"
                                                                class="btn btn-danger btn-icon btn-circle"
                                                                @click.prevent="
                                                                    removeMigraTesserato(indexTesserato)
                                                                    ">
                                                                <i class="fas fa-trash-alt"></i>
                                                            </button>
                                                        </td>
                                                    </tr>

                                                    <tr v-if="!migraTesserati.length">
                                                        <td colspan="11" class="text-center">
                                                            Nessun tesserato da migrare
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="d-flex justify-content-between">
                                            <button v-if="migraTesserati.length" type="button" class="btn btn-success"
                                                @click.prevent="saveMigraTesserati">
                                                <i class="fas fa-save"></i> Salva
                                            </button>

                                            <button type="button" class="btn btn-danger"
                                                @click.prevent="closeMigraTesseratiModal">
                                                <i class="fas fa-window-close"></i> Chiudi
                                            </button>
                                        </div>
                                    </b-overlay>
                                </b-modal>
                            </b-tab>

                            <b-modal ref="migrateEnrolledUserModal" size="xl" hide-footer title="Migra Tesserato">
                                <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur"
                                    spinner-variant="primary">
                                    <SmartForm ref="migrateEnrolledUserForm" base_url="/sportingclub/enrolled-user"
                                        base_path="/sportingclub/enrolled-user" form_type="edit" form_variant="migrate"
                                        :redirect_after_save="false" :item_id="iddeleteenrolleduser" :fixed_filters="[
                                            { name: 'action', filter: 'migrate' },
                                            { name: 'idsportingclub', filter: $route.params.id },
                                        ]" v-on:values-saved="aggiornaMigrateForm">
                                        <template v-slot:title>
                                            <a href="#" @click.prevent="closeMigrateEnrolledUserModal"
                                                class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                <i class="fas fa-window-close icon-sm"></i> Chiudi
                                            </a>
                                        </template>
                                        <template v-slot:toolbar="slotProps">
                                            <div class="d-flex flex-wrap justify-content-end">
                                                <a href="#" @click.prevent="
                                                    confirmMigrateEnrolledUser(slotProps.values.id)
                                                    " class="mt-2 btn btn-success font-weight-bolder">
                                                    <i class="fas fa-fast-forward"></i> Conferma
                                                    Migrazione
                                                </a>
                                            </div>
                                        </template>
                                        <template v-slot:footer="slotProps">
                                            <div class="card-footer border-0 py-5">
                                                <div class="d-flex flex-wrap justify-content-between">
                                                    <a href="#" @click.prevent="closeMigrateEnrolledUserModal"
                                                        class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                        <i class="fas fa-window-close icon-sm"></i> Chiudi
                                                    </a>

                                                    <a href="#" @click.prevent="
                                                        confirmMigrateEnrolledUser(slotProps.values.id)
                                                        " class="mt-2 btn btn-success font-weight-bolder">
                                                        <i class="fas fa-fast-forward"></i> Conferma
                                                        Migrazione
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </SmartForm>
                                </b-overlay>
                            </b-modal>

                            <b-tab v-if="
                                sodalizio.is_last_affiliation &&
                                (isSodalizioAttivo || isSuperadminOrSegreteria)
                            " class="border border-primary border-top-0 rounded-bottom" title="Inserimento Tesserato"
                                :title-link-class="linkClassTesseramenti(1)">
                                <div class="alert alert-danger">
                                    <h3>AVVISO IMPORTANTE</h3>

                                    <p>
                                        Per le ASD, SSD e BAS: è necessario selezionare per i
                                        Tesserati almeno una delle discipline CONI riconosciute.<br />
                                        Dal 1 gennaio 2018 è obbligatorio effettuare tutti i
                                        tesseramenti ai fini della convalida dell'affiliazione.
                                    </p>
                                </div>

                                <SmartForm ref="tesseratoForm" :base_url="tesseratoFormSaveUrl"
                                    :base_path="tesseratoFormSaveUrl" form_type="new" v-on:values-saved="afterSaveDraft"
                                    :fixed_filters="[
                                        { name: 'idsportingclub', filter: $route.params.id },
                                    ]" :redirect_after_save="false">
                                    <template v-slot:title>
                                        <h3 class="font-weight-bolder text-dark font-size-h3">
                                            Inserimento Tesserato
                                        </h3>
                                    </template>

                                    <template v-slot:toolbar>
                                        <a href="#" @click.prevent="saveDraft"
                                            class="mt-2 btn btn-secondary font-weight-bolder mr-3"><i
                                                class="fas fa-save"></i> Salva in Bozza</a>
                                        <a href="#" @click.prevent="saveDirect"
                                            class="mt-2 btn btn-info font-weight-bolder mr-3"><i
                                                class="fas fa-paper-plane"></i>Salva e Invia
                                            Direttamente</a>
                                    </template>

                                    <template v-slot:footer>
                                        <div class="d-flex justify-content-end" style="padding-right: 2.25rem">
                                            <a href="#" @click.prevent="saveDraft"
                                                class="mt-2 btn btn-secondary font-weight-bolder mr-3"><i
                                                    class="fas fa-save"></i> Salva in Bozza</a>
                                            <a href="#" @click.prevent="saveDirect"
                                                class="mt-2 btn btn-info font-weight-bolder mr-3"><i
                                                    class="fas fa-paper-plane"></i>Salva e Invia
                                                Direttamente</a>
                                        </div>
                                    </template>
                                </SmartForm>
                            </b-tab>

                            <b-tab v-if="
                                sodalizio.is_last_affiliation &&
                                (isSodalizioAttivo || isSuperadminOrSegreteria)
                            " class="border border-primary border-top-0 rounded-bottom" title="Tesseramenti in Bozza"
                                :title-link-class="linkClassTesseramenti(2)">
                                <SmartTable ref="tesseramentiinbozza" url="/sportingclub/enrolled-user-draft/index"
                                    :is_dialog="true" base_url="/sportingclub/enrolled-user-draft" base_path="/"
                                    :fixed_filters="[
                                        { name: 'idsportingclub', filter: $route.params.id },
                                        { name: 'enrolledstate', filter: 'In Bozza' },
                                    ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalTesseramenti">
                                    <template v-slot:title>
                                        <h2 class="card-title">
                                            <span
                                                class="card-label font-weight-bolder text-dark font-size-h3">Tesseramenti
                                                in Bozza</span>
                                        </h2>
                                    </template>

                                    <template v-slot:title-actions>
                                        <div class="card-toolbar">
                                            <a href="#" class="'mt-2 btn btn-info font-weight-bolder"
                                                @click.prevent="inserisciInviaTesseratiBozza">
                                                <i class="fas fa-paper-plane"></i>
                                                Inserisci e Invia Tesserati Selezionati
                                            </a>
                                        </div>
                                    </template>

                                    <template v-slot:before-filters>
                                        <div class="alert alert-danger">
                                            <h3>AVVISO IMPORTANTE</h3>

                                            <p>
                                                Per le ASD, SSD e BAS: è necessario selezionare per i
                                                Tesserati almeno una delle discipline CONI
                                                riconosciute.<br />
                                                Dal 1 gennaio 2018 è obbligatorio effettuare tutti i
                                                tesseramenti ai fini della convalida dell'affiliazione.
                                            </p>
                                        </div>
                                    </template>
                                </SmartTable>
                            </b-tab>

                            <b-tab v-if="
                                sodalizio.is_last_affiliation &&
                                (isSodalizioAttivo || isSuperadminOrSegreteria)
                            " class="border border-primary border-top-0 rounded-bottom" title="Importazione Massiva"
                                :title-link-class="linkClassTesseramenti(3)">
                                <h3>Caricamento Massivo Tesserati da File</h3>
                                <div class="alert alert-danger">
                                    Il nuovo strumento di caricamento massivo dei tesserati è
                                    online.<br />
                                    <br />
                                    Una volta caricato e processato il file, il sistema mostrerà
                                    in tempo reale le anomalie riscontrate.<br />
                                    <br />
                                    Potrete correggere le anomalie evidenziate dal sistema ed
                                    effettuare tutte le modifiche tra le quali l'aggiunta delle
                                    discipline CONI e la variazione delle assicurazioni.<br />
                                    <br />
                                    Per terminare correttamente la procedura di caricamento
                                    tesserati dovrete cliccare sul bottone verde
                                    <span style="font-style: italic"><strong>&quot;inserisci
                                            tesserati&quot;</strong></span>.<br />
                                    <br />
                                    <strong>N.B. Si ricorda che chiudendo la pagina il sistema non
                                        terrà traccia dell'elenco caricato.</strong>
                                </div>

                                <div class="form-group">
                                    <FileUpload id="filetesserati" name="filetesserati" :multiple="false"
                                        v-model="filetesserati">
                                    </FileUpload>

                                    <div v-for="(error, index) in fileErrors.filename" class="invalid-feedback d-block">
                                        {{ error }}
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button type="button" class="btn btn-primary" @click.prevent="processTesserati">
                                        <i class="fas fa-save"></i> Carica e Processa
                                    </button>
                                    <button type="button" class="btn btn-info" @click.prevent="saveTesserati">
                                        <i class="fas fa-save"></i> Salva
                                    </button>

                                    <a href="media/Modello_tesseramento_ACSI.ods" class="btn btn-success"
                                        target="_blank"><i class="fas fa-universal-access"></i> Scarica Modello
                                        ODS</a>
                                    <a href="media/Modello_tesseramento_ACSI.xlsx" class="btn btn-success"
                                        target="_blank"><i class="fas fa-file-excel"></i> Scarica Modello XLSX</a>

                                    <button type="button" class="btn btn-success" @click.prevent="newTesseratoTop">
                                        <i class="fas fa-plus"></i> Nuovo Tesserato
                                    </button>
                                </div>

                                <div class="table-responsive mt-3" style="min-height: 600px">
                                    <table
                                        class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered"
                                        style="min-height: 400px">
                                        <thead>
                                            <tr>
                                                <th class="bg-primary">
                                                    <span class="text-white">Tessera</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Cognome</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Nome</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Codice Fiscale</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Qualifica</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">E-mail</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Cellulare</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Assicurazione</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Discipline CONI</span>
                                                </th>
                                                <th class="bg-primary">
                                                    <span class="text-white">Discipline ACSI</span>
                                                </th>
                                                <th class="bg-primary text-center pr-0">
                                                    <span class="text-white">Azioni</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-if="tesserati.length" v-for="(item, indexTesserato) in tesserati">
                                                <td>
                                                    <multiselect style="width: 100%; min-width: 300px"
                                                        v-model="item.idmembercard" :options="optionsMembercards"
                                                        :multiple="false" :close-on-select="true"
                                                        :clear-on-select="false" placeholder="Seleziona una opzione"
                                                        label="label" track-by="id" :allow-empty="false" selectLabel=""
                                                        selectedLabel="" deselectLabel="" showLabels="">
                                                        <template v-slot:noOptions>
                                                            Nessuna opzione disponibile
                                                        </template>
                                                    </multiselect>
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato][
                                                        'idmembercard'
                                                        ] !== 'undefined'
                                                    " class="text-danger">
                                                        {{
                                                            tesseratiErrors[indexTesserato]["idmembercard"]
                                                        }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <input class="border border-primary" type="text"
                                                        v-model="item.surname" />
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['surname'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["surname"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <input class="border border-primary" type="text"
                                                        v-model="item.name" />
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['name'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["name"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <input class="border border-primary mr-2" type="text"
                                                        v-model="item.taxcode" />
                                                    <TaxCodeBtn :modalid="'tesserato-taxcode-' + indexTesserato"
                                                        v-model="item.taxcode"></TaxCodeBtn>
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['taxcode'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["taxcode"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <multiselect style="width: 100%; min-width: 300px"
                                                        v-model="item.idroletype" :options="optionsRoleTypes"
                                                        :multiple="false" :close-on-select="true"
                                                        :clear-on-select="false" placeholder="Seleziona una opzione"
                                                        label="label" track-by="id" :allow-empty="false" selectLabel=""
                                                        selectedLabel="" deselectLabel="" showLabels="">
                                                        <template v-slot:noOptions>
                                                            Nessuna opzione disponibile
                                                        </template>
                                                    </multiselect>
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['idroletype'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["idroletype"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <input class="border border-primary" type="text"
                                                        v-model="item.email" />
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['email'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["email"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <input class="border border-primary" type="text"
                                                        v-model="item.phonenumber" />
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['phonenumber'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["phonenumber"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <multiselect style="width: 100%; min-width: 300px"
                                                        v-model="item.idinsurrance" :options="optionsInsurances"
                                                        :multiple="false" :close-on-select="true"
                                                        :clear-on-select="false" placeholder="Seleziona una opzione"
                                                        label="label" track-by="id" :allow-empty="false" selectLabel=""
                                                        selectedLabel="" deselectLabel="" showLabels="">
                                                        <template v-slot:noOptions>
                                                            Nessuna opzione disponibile
                                                        </template>
                                                    </multiselect>
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['idinsurance'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["idinsurance"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <multiselect style="width: 100%; min-width: 300px"
                                                        v-model="item.coni2activities" :options="optionsConi2Activities"
                                                        :multiple="true" :close-on-select="false"
                                                        :clear-on-select="false" placeholder="Seleziona una opzione"
                                                        label="label" track-by="id" :allow-empty="true" selectLabel=""
                                                        selectedLabel="" deselectLabel="" showLabels="">
                                                        <template v-slot:noOptions>
                                                            Nessuna opzione disponibile
                                                        </template>
                                                    </multiselect>
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato][
                                                        'coni2activities'
                                                        ] !== 'undefined'
                                                    " class="text-danger">
                                                        {{
                                                            tesseratiErrors[indexTesserato]["coni2activities"]
                                                        }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <multiselect style="width: 100%; min-width: 300px"
                                                        v-model="item.activities" :options="optionsActivities"
                                                        :multiple="true" :close-on-select="false"
                                                        :clear-on-select="false" placeholder="Seleziona una opzione"
                                                        label="label" track-by="id" :allow-empty="true" selectLabel=""
                                                        selectedLabel="" deselectLabel="" showLabels="">
                                                        <template v-slot:noOptions>
                                                            Nessuna opzione disponibile
                                                        </template>
                                                    </multiselect>
                                                    <div v-if="
                                                        typeof tesseratiErrors[indexTesserato] !==
                                                        'undefined' &&
                                                        tesseratiErrors[indexTesserato]['activities'] !==
                                                        'undefined'
                                                    " class="text-danger">
                                                        {{ tesseratiErrors[indexTesserato]["activities"] }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-danger btn-icon btn-circle"
                                                        @click.prevent="removeTesserato(indexTesserato)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                            <tr v-if="!tesserati.length">
                                                <td colspan="11" class="text-center">
                                                    Nessun tesserato processato
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button type="button" class="btn btn-info" @click.prevent="saveTesserati">
                                        <i class="fas fa-save"></i> Salva
                                    </button>
                                    <button type="button" class="btn btn-success" @click.prevent="newTesseratoBottom">
                                        <i class="fas fa-plus"></i> Nuovo Tesserato
                                    </button>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Impianti"
                        :title-link-class="linkClass(6)">
                        <SmartTable ref="impianti" url="/sportingclub/facility/index" :is_dialog="true"
                            base_url="/sportingclub/facility" base_path="/sportingclub/facility" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalFacilities">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Gestione
                                        Impianti</span>
                                </h2>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab v-if="
                        ($store.getters.currentUser.usertype === 'Comitato' ||
                            $store.getters.currentUser.usertype ===
                            'Segreteria Nazionale' ||
                            $store.getters.currentUser.usertype === 'Superadmin') &&
                        sodalizio.is_last_affiliation
                    " class="border border-primary border-top-0 rounded-bottom" title="Modifica Sodalizio"
                        :title-link-class="linkClass(7)">
                        <SportingClubForm ref="modificaSodalizioForm" base_url="/sportingclub/sportingclub-change"
                            base_path="/sportingclub/sportingclub-change" form_type="edit" :redirect_after_save="false"
                            :item_id="sodalizio.id" v-on:values-saved="aggiornaSodalizio">
                            <template v-slot:title>
                                <h3 class="card-label">Modifica Sodalizio</h3>
                            </template>
                            <template v-slot:toolbar>
                                <span></span>
                            </template>
                            <template v-slot:footer>
                                <span></span>
                            </template>
                        </SportingClubForm>
                    </b-tab>

                    <b-tab v-if="
                        $store.getters.currentUser.usertype === 'Comitato' ||
                        $store.getters.currentUser.usertype === 'Segreteria Nazionale' ||
                        $store.getters.currentUser.usertype === 'Superadmin'
                    " class="border border-primary border-top-0 rounded-bottom" title="Listino Sodalizio"
                        :title-link-class="sodalizio.is_last_affiliation ? linkClass(8) : linkClass(7)
                            ">
                        <SmartTable ref="prezzitessere" url="/sportingclub/membercard-price-sportingclub/index"
                            :is_dialog="true" base_url="/sportingclub/membercard-price-sportingclub"
                            base_path="/sportingclub/membercard-price-sportingclub" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziTessere">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi
                                        Tessere</span>
                                </h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="prezziassicurazioni" url="/sportingclub/insurance-price-sportingclub/index"
                            :is_dialog="true" base_url="/sportingclub/insurance-price-sportingclub"
                            base_path="/sportingclub/insurance-price-sportingclub" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziAssicurazioni">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi
                                        Assicurazioni</span>
                                </h2>
                            </template>
                        </SmartTable>

                        <SmartTable ref="prezzicertificati" url="/sportingclub/certificate-price-sportingclub/index"
                            :is_dialog="true" base_url="/sportingclub/certificate-price-sportingclub"
                            base_path="/sportingclub/certificate-price-sportingclub" :fixed_filters="[
                                { name: 'idsportingclub', filter: $route.params.id },
                            ]" custom_class="mx-0 px-0" v-on:close-modal="closeModalPrezziCertificati">
                            <template v-slot:title>
                                <h2 class="card-title">
                                    <span class="card-label font-weight-bolder text-dark font-size-h3">Listino Prezzi
                                        Certificati</span>
                                </h2>
                            </template>
                        </SmartTable>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SmartTable from "@/view/components/SmartTable.vue";
import SportingClubForm from "@/view/components/SportingClubForm.vue";
import FileUpload from "@/view/components/FileUpload.vue";
import SmartForm from "@/view/components/SmartForm.vue";
import TaxCodeBtn from "@/view/components/TaxCodeBtn.vue";
import EnrolledUserInsurance from "@/view/components/EnrolledUserInsurance.vue";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            loading: false,

            show_rssubscription: false,

            date_shortcuts: [
                {
                    text: "Oggi",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        return item;
                    },
                },
                {
                    text: "Ieri",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        item.setTime(item.getTime() - 3600 * 1000 * 24);

                        return item;
                    },
                },
                {
                    text: "Ultima settimana",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 7);

                        return item;
                    },
                },
                {
                    text: "Ultimo mese",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 30);

                        return item;
                    },
                },
                {
                    text: "Ultimi 3 mesi",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 90);

                        return item;
                    },
                },
                {
                    text: "Ultimi 6 mesi",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 180);

                        return item;
                    },
                },
                {
                    text: "Ultimo anno",
                    onClick() {
                        const date = new Date();

                        const item = new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate(),
                            0,
                            0,
                            0,
                            0
                        );

                        item.setTime(item.getTime() - 3600 * 1000 * 24 * 365);

                        return item;
                    },
                },
            ],

            iddeleteenrolleduser: null,
            idinsuranceenrolleduser: null,

            filetesserati: "",
            fileErrors: [],
            tesseratiErrors: [],
            tesserati: [],
            loaded: false,
            tabIndex: 0,
            tabIndexTesseramenti: 0,
            sodalizio: {},
            updatingCertificati: false,
            tesseratoFormSaveUrl: "/sportingclub/enrolled-user-draft",

            migraTesseratiErrors: [],
            migraTesserati: [],

            optionsMembercards: [],
            optionsRoleTypes: [],
            optionsInsurances: [],
            optionsActivities: [],
            optionsConi2Activities: [],

            nuovoStatoAffiliazione: "",

            defaultidannualitytessere: "",
            defaultidannualitytesseramenti: "",
            idannualitytessere: "",
            idannualitytesseramenti: "",
            idannualityaffiliation: "",
            can_migrate: false,
            annualities: [],
        };
    },

    created() {
        this.loadManageData();

        this.initPage();
    },

    computed: {
        token() {
            return JwtService.getToken();
        },

        current_year_tessere() {
            let idannuality = null;
            let found = null;

            idannuality = this.idannualitytessere;
            found = this.annualities.find(function (item) {
                return item.id === idannuality;
            });

            if (found) {
                return found.label;
            }

            idannuality = this.defaultidannualitytessere;
            found = this.annualities.find(function (item) {
                return item.id === idannuality;
            });

            if (found) {
                return found.label;
            }

            return "";
        },
        current_year_tesseramenti() {
            let idannuality = null;
            let found = null;

            idannuality = this.idannualitytesseramenti;
            found = this.annualities.find(function (item) {
                return item.id === idannuality;
            });

            if (found) {
                return found.label;
            }

            idannuality = this.defaultidannualitytesseramenti;
            found = this.annualities.find(function (item) {
                return item.id === idannuality;
            });

            if (found) {
                return found.label;
            }

            return "";
        },

        isSodalizioAttivo() {
            if (
                typeof this.sodalizio !== "undefined" &&
                typeof this.sodalizio.affiliations !== "undefined" &&
                this.sodalizio.affiliations.length &&
                this.sodalizio.affiliations[0].affiliationstates.affiliationstate ===
                "Accettata"
            )
                return true;

            return false;
        },

        isSuperadminOrSegreteria() {
            return ["Superadmin", "Segreteria Nazionale"].includes(
                this.$store.getters.currentUser.usertype
            );
        },
    },

    watch: {
        idannualitytessere(value) {
            let vm = this;
            setTimeout(function () {
                vm.$refs.stocktessere.refresh();
            }, 100);
        },

        idannualitytesseramenti(value) {
            let vm = this;
            setTimeout(function () {
                vm.$refs.tesseramentiRicerca.refresh();
            }, 100);
        },
    },

    components: {
        SmartTable,
        SportingClubForm,
        FileUpload,
        SmartForm,
        TaxCodeBtn,
        EnrolledUserInsurance,
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Affiliazioni", route: "/sportingclub/gestione" },
            { title: "Gestione" },
        ]);

        this.$watch(
            () => {
                let fields = this.$refs.certificati.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.certificati.$refs.modalcomponent.form_type === "edit" &&
                        (this.$refs.certificati.componentValuesloaded === null ||
                            this.$refs.certificati.componentValuesloaded === false)
                    ) {
                        return null;
                    }

                    return this.$refs.certificati.$refs.modalcomponent.form_type;
                }

                return null;
            },
            (value) => {
                if (typeof value !== "undefined" && value === "new") {
                    let field = this.findField(
                        this.$refs.certificati.$refs.modalcomponent,
                        "idcertificate"
                    );
                    if (field) {
                        this.$set(
                            this.$refs.certificati.$refs.modalcomponent.fields[0],
                            "type",
                            "integer"
                        );
                        this.$set(
                            this.$refs.certificati.$refs.modalcomponent.fields[0],
                            "is_visible_for_store",
                            true
                        );
                        this.$set(
                            this.$refs.certificati.$refs.modalcomponent.fields[0],
                            "is_visible_for_update",
                            true
                        );
                    }
                } else if (
                    (typeof value !== "undefined" && value === "view") ||
                    value === "delete"
                ) {
                    let field = this.findField(
                        this.$refs.certificati.$refs.modalcomponent,
                        "idcertificate"
                    );
                    if (field) {
                        this.$set(
                            this.$refs.certificati.$refs.modalcomponent.fields[0],
                            "type",
                            "integer"
                        );
                        this.$set(
                            this.$refs.certificati.$refs.modalcomponent.fields[0],
                            "is_visible_for_store",
                            true
                        );
                        this.$set(
                            this.$refs.certificati.$refs.modalcomponent.fields[0],
                            "is_visible_for_update",
                            true
                        );
                    }
                } else if (typeof value !== "undefined" && value === "edit") {
                    if (this.$refs.certificati.componentValuesloaded) {
                        let field = this.findField(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idcertificate"
                        );
                        if (field) {
                            let message = "";
                            let option = field.filter_options.find(function (item) {
                                if (field.value) {
                                    return item.id === field.value.id;
                                }
                            });

                            if (option) message = option.displayname;

                            this.$set(
                                this.$refs.certificati.$refs.modalcomponent.fields[0],
                                "type",
                                "hidden"
                            );
                            this.$set(
                                this.$refs.certificati.$refs.modalcomponent.fields[0],
                                "message",
                                message
                            );
                            this.$set(
                                this.$refs.certificati.$refs.modalcomponent.fields[0],
                                "is_visible_for_store",
                                true
                            );
                            this.$set(
                                this.$refs.certificati.$refs.modalcomponent.fields[0],
                                "is_visible_for_update",
                                true
                            );
                        }
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.certificati.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.certificati.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.certificati.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.certificati.componentValuesloaded === null ||
                            this.$refs.certificati.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.certificati.$refs.modalcomponent,
                        "idcertificate"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) {
                        return value.id;
                    }

                    return null;
                } else return null;
            },
            (id) => {
                this.updatingCertificati = true;

                let fieldAnno = this.findField(
                    this.$refs.certificati.$refs.modalcomponent,
                    "idannuality"
                );
                let fieldAnnoValue = null;
                if (fieldAnno && fieldAnno.value)
                    fieldAnnoValue = JSON.parse(JSON.stringify(fieldAnno.value));

                let fieldUser = this.findField(
                    this.$refs.certificati.$refs.modalcomponent,
                    "iduser"
                );
                let fieldUserValue = null;
                if (fieldUser && fieldUser.value)
                    fieldUserValue = JSON.parse(JSON.stringify(fieldUser.value));

                let fieldRoleType = this.findField(
                    this.$refs.certificati.$refs.modalcomponent,
                    "idroletype"
                );
                let fieldRoleTypeValue = null;
                if (fieldRoleType && fieldRoleType.value)
                    fieldRoleTypeValue = JSON.parse(JSON.stringify(fieldRoleType.value));

                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "idannuality",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "iduser",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "idroletype",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "qualification",
                    false
                );

                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "heading1",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "email",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "surname",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "name",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "tax",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "birthplace",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "birthdate",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "city",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "zipcode",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "phonenumber",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "acsicode",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "heading2",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "horsename",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "birthyear",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "sex",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "nationality",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "race",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "coat",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "document",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "microchip",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "equineplaced",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "identityownerdocument",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "identityequinedocument",
                    false
                );
                this.setFieldVisible(
                    this.$refs.certificati.$refs.modalcomponent,
                    "nondpadocument",
                    false
                );

                if (id) {
                    if (id !== 112 && id !== 106 && id !== 113) {
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idannuality",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idannuality",
                            "type",
                            "integer"
                        );

                        let vm = this;
                        if (fieldAnno) {
                            this.loadOptions(
                                this.$refs.certificati.$refs.modalcomponent,
                                "certificates_annualities",
                                "idsportingclub",
                                this.$route.params.id,
                                "idannuality",
                                function (options) {
                                    options.unshift({
                                        id: 0,
                                        solarsannualitydisplay: "Seleziona un anno",
                                    });
                                },
                                function (options) {
                                    if (fieldAnno) {
                                        vm.$set(fieldAnno, "filter_options", options);

                                        if (fieldAnnoValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldAnnoValue;
                                            });
                                            if (option)
                                                fieldAnno.value = JSON.parse(JSON.stringify(option));
                                        }
                                    }
                                }
                            );
                        }
                    } else if (id === 113) {
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idannuality",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idannuality",
                            "type",
                            "integer"
                        );

                        let vm = this;
                        if (fieldAnno) {
                            this.loadOptions(
                                this.$refs.certificati.$refs.modalcomponent,
                                "certificates_annualities",
                                "idsportingclub",
                                this.$route.params.id,
                                "idannuality",
                                function (options) {
                                    options.unshift({
                                        id: 0,
                                        solarsannualitydisplay: "Seleziona un anno",
                                    });
                                },
                                function (options) {
                                    if (fieldAnno) {
                                        vm.$set(fieldAnno, "filter_options", options);

                                        if (fieldAnnoValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldAnnoValue;
                                            });
                                            if (option)
                                                fieldAnno.value = JSON.parse(JSON.stringify(option));
                                        }
                                    }
                                }
                            );
                        }

                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "heading1",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "email",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "surname",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "name",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "tax",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "birthplace",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "birthdate",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "city",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "zipcode",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "phonenumber",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "acsicode",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "heading2",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "horsename",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "birthyear",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "sex",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "nationality",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "race",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "coat",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "document",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "microchip",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "equineplaced",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "identityownerdocument",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "identityequinedocument",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "nondpadocument",
                            true
                        );
                    } else {
                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idannuality",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idannuality",
                            "type",
                            "integer"
                        );

                        let vm = this;
                        if (fieldAnno) {
                            this.loadOptions(
                                this.$refs.certificati.$refs.modalcomponent,
                                "certificates_annualities",
                                "idsportingclub",
                                this.$route.params.id,
                                "idannuality",
                                function (options) {
                                    options.unshift({
                                        id: 0,
                                        solarsannualitydisplay: "Seleziona un anno",
                                    });
                                },
                                function (options) {
                                    if (fieldAnno) {
                                        vm.$set(fieldAnno, "filter_options", options);

                                        if (fieldAnnoValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldAnnoValue;
                                            });
                                            if (option)
                                                fieldAnno.value = JSON.parse(JSON.stringify(option));
                                        }
                                    }
                                }
                            );
                        }

                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "iduser",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.certificati.$refs.modalcomponent,
                            "iduser",
                            "type",
                            "integer"
                        );

                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idroletype",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idroletype",
                            "type",
                            "integer"
                        );

                        if (fieldUser) {
                            this.loadOptions(
                                this.$refs.certificati.$refs.modalcomponent,
                                "boards",
                                "idsportingclub",
                                this.$refs.certificati.fixed_filters[0].filter,
                                "iduser",
                                function (options) {
                                    options.unshift({
                                        id: 0,
                                        displaysurname: "Seleziona un Operatore",
                                    });

                                    vm.updatingCertificati = false;
                                },
                                function (options) {
                                    if (fieldUser) {
                                        vm.$set(fieldUser, "filter_options", options);

                                        if (fieldUserValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldUserValue;
                                            });
                                            if (option) {
                                                fieldUser.value = JSON.parse(JSON.stringify(option));

                                                if (fieldRoleTypeValue !== null) {
                                                    vm.loadOptions(
                                                        vm.$refs.certificati.$refs.modalcomponent,
                                                        "roletypes",
                                                        "iduser",
                                                        option.id,
                                                        "idroletype",
                                                        function (options) {
                                                            options.unshift({
                                                                id: 0,
                                                                sportingclubroletype:
                                                                    "Seleziona un Ruolo Organigramma",
                                                            });
                                                        },
                                                        function (options) {
                                                            if (fieldRoleType) {
                                                                vm.$set(
                                                                    fieldRoleType,
                                                                    "filter_options",
                                                                    options
                                                                );

                                                                if (
                                                                    typeof fieldRoleType.value !== "undefined"
                                                                ) {
                                                                    if (fieldRoleTypeValue !== null) {
                                                                        let option = options.find(function (item) {
                                                                            return item.id === fieldRoleTypeValue;
                                                                        });
                                                                        if (option)
                                                                            fieldRoleType.value = JSON.parse(
                                                                                JSON.stringify(option)
                                                                            );
                                                                    } else {
                                                                        fieldRoleType.value = JSON.parse(
                                                                            JSON.stringify(options[0])
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        }
                                    }

                                    vm.updatingCertificati = false;
                                }
                            );
                        }

                        this.setFieldVisible(
                            this.$refs.certificati.$refs.modalcomponent,
                            "qualification",
                            true
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.certificati.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.certificati.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.certificati.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.certificati.componentValuesloaded === null ||
                            this.$refs.certificati.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    if (this.updatingCertificati) return false;

                    let field = this.findField(
                        this.$refs.certificati.$refs.modalcomponent,
                        "iduser"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldRoleType = null;
                let fieldRoleTypeValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldRoleType = this.findField(
                            this.$refs.certificati.$refs.modalcomponent,
                            "idroletype"
                        );
                        if (
                            typeof fieldRoleType !== "undefined" &&
                            fieldRoleType &&
                            typeof fieldRoleType.value !== "undefined"
                        )
                            fieldRoleTypeValue = JSON.parse(
                                JSON.stringify(fieldRoleType.value)
                            );
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.certificati.$refs.modalcomponent,
                            "roletypes",
                            "iduser",
                            value.id,
                            "idroletype",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    sportingclubroletype: "Seleziona un Ruolo Organigramma",
                                });
                            },
                            function (options) {
                                fieldRoleType = vm.findField(
                                    vm.$refs.certificati.$refs.modalcomponent,
                                    "idroletype"
                                );

                                if (fieldRoleType) {
                                    vm.$set(fieldRoleType, "filter_options", options);

                                    if (typeof fieldRoleType.value !== "undefined") {
                                        if (fieldRoleTypeValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldRoleTypeValue;
                                            });
                                            if (option)
                                                fieldRoleType.value = JSON.parse(
                                                    JSON.stringify(option)
                                                );
                                        } else {
                                            fieldRoleType.value = JSON.parse(
                                                JSON.stringify(options[0])
                                            );
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.documenti.componentFields;

                if (fields.length) {
                    let field = this.findField(
                        this.$refs.documenti.$refs.modalcomponent,
                        "idattachmenttype"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) {
                        return value.id;
                    }

                    return null;
                } else return null;
            },
            (id) => {
                let fieldAnno = this.findField(
                    this.$refs.documenti.$refs.modalcomponent,
                    "idannuality"
                );
                let fieldAnnoValue = null;
                if (fieldAnno && fieldAnno.value)
                    fieldAnnoValue = JSON.parse(JSON.stringify(fieldAnno.value));

                this.setFieldVisible(
                    this.$refs.documenti.$refs.modalcomponent,
                    "idannuality",
                    false
                );

                this.setFieldVisible(
                    this.$refs.documenti.$refs.modalcomponent,
                    "documentemissiondate",
                    false
                );
                this.setFieldAttribute(
                    this.$refs.documenti.$refs.modalcomponent,
                    "documentemissiondate",
                    "label",
                    "Data"
                );

                if (id) {
                    if (id === 2911) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data registrazione"
                        );
                    }

                    if (id === 2912) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data registrazione"
                        );
                    }

                    if (id === 2919) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "idannuality",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "idannuality",
                            "type",
                            "integer"
                        );

                        let vm = this;
                        if (fieldAnno && fieldAnno.filter_options === null) {
                            vm.loadOptions(
                                this.$refs.documenti.$refs.modalcomponent,
                                "annualities",
                                "idsportingclub",
                                vm.$route.params.id,
                                "idannuality",
                                function (options) {
                                    options.unshift({
                                        id: 0,
                                        solarsannualitydisplay: "Seleziona un anno",
                                    });
                                },
                                function (options) {
                                    if (fieldAnno) {
                                        vm.$set(fieldAnno, "filter_options", options);

                                        if (fieldAnnoValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldAnnoValue;
                                            });
                                            fieldAnno.value = JSON.parse(JSON.stringify(option));
                                        }
                                    }
                                }
                            );
                        }
                    }

                    if (id === 2920) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale Assemblea Consiglio"
                        );
                    }

                    if (id === 2921) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale modifica Statuto"
                        );
                    }

                    if (id === 2935) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale modifica Legale Rappresentante"
                        );
                    }

                    if (id === 2937) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Modifiche e aggiornamenti - Cambio Natura Giuridica"
                        );
                    }

                    if (id === 2938) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale di modifica sede legale"
                        );
                    }

                    if (id === 2939) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Visura Camerale"
                        );
                    }

                    if (id === 2940) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Nomina Responsabile contro Abusi, Violenze e Discriminazioni"
                        );
                    }

                    if (id === 2941) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data MOG"
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.documenti.componentFields;

                if (fields.length) {
                    if (fields.length) {
                        if (
                            (this.$refs.documenti.$refs.modalcomponent.form_type &&
                                this.$refs.documenti.$refs.modalcomponent.form_type ===
                                "edit" &&
                                this.$refs.documenti.componentValuesloaded) ||
                            (this.$refs.documenti.$refs.modalcomponent.form_type &&
                                this.$refs.documenti.$refs.modalcomponent.form_type === "new")
                        ) {
                            let field = this.findField(
                                this.$refs.documenti.$refs.modalcomponent,
                                "attachment"
                            );
                            let value = this.getFieldValue(field);

                            if (value) {
                                return value;
                            }

                            return {
                                path: "",
                            };
                        }
                    }

                    return null;
                } else return null;
            },
            (newAttachment, oldAttachment) => {
                if (typeof newAttachment === "undefined") return;
                if (typeof oldAttachment === "undefined") return;

                if (newAttachment === null) return;
                if (oldAttachment === null) return;

                if (newAttachment.path !== oldAttachment.path) {
                    this.setFieldAttribute(
                        this.$refs.documenti.$refs.modalcomponent,
                        "documentemissiondate",
                        "value",
                        ""
                    );
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.consiglio.componentFields;

                if (fields.length) {
                    let field = this.findField(
                        this.$refs.consiglio.$refs.modalcomponent,
                        "idroletype"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) {
                        return value.id;
                    }

                    return null;
                } else return null;
            },
            (id) => {
                if (id) {
                    if (id === 2101 || id === 2115) {
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idregion",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idprovstate",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idcity",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "streetaddress",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "streetnumber",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "zipcode",
                            true
                        );
                    } else {
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idregion",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idprovstate",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idcity",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "streetaddress",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "streetnumber",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "zipcode",
                            false
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.organigramma.componentFields;

                if (fields.length) {
                    let field = this.findField(
                        this.$refs.organigramma.$refs.modalcomponent,
                        "idroletype"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) {
                        return value.id;
                    }

                    return null;
                } else return null;
            },
            (id) => {
                if (id) {
                    if (id === 2101 || id === 2115) {
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idregion",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idprovstate",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idcity",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "streetaddress",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "streetnumber",
                            true
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "zipcode",
                            true
                        );
                    } else {
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idregion",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idprovstate",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idcity",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "streetaddress",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "streetnumber",
                            false
                        );
                        this.setFieldVisible(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "zipcode",
                            false
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.consiglio.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.consiglio.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.consiglio.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.consiglio.componentValuesloaded === null ||
                            this.$refs.consiglio.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.consiglio.$refs.modalcomponent,
                        "idregion"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldProvstate = null;
                let fieldProvstateValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldProvstate = this.findField(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idprovstate"
                        );
                        fieldProvstateValue = JSON.parse(
                            JSON.stringify(fieldProvstate.value)
                        );
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "provstates",
                            "idregion",
                            value.id,
                            "idprovstate",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    provstatecode: "Seleziona una provincia",
                                });
                            },
                            function (options) {
                                fieldProvstate = vm.findField(
                                    vm.$refs.consiglio.$refs.modalcomponent,
                                    "idprovstate"
                                );

                                if (fieldProvstate) {
                                    vm.$set(fieldProvstate, "filter_options", options);

                                    if (typeof fieldProvstate.value !== "undefined") {
                                        if (fieldProvstateValue !== null) {
                                            fieldProvstate.value = fieldProvstateValue;
                                        } else {
                                            fieldProvstate.value = JSON.parse(
                                                JSON.stringify(options[0])
                                            );
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.impianti.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.impianti.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.impianti.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.impianti.componentValuesloaded === null ||
                            this.$refs.impianti.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.impianti.$refs.modalcomponent,
                        "idregion"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldProvstate = null;
                let fieldProvstateValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldProvstate = this.findField(
                            this.$refs.impianti.$refs.modalcomponent,
                            "idprovstate"
                        );
                        fieldProvstateValue = JSON.parse(
                            JSON.stringify(fieldProvstate.value)
                        );
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.impianti.$refs.modalcomponent,
                            "provstates",
                            "idregion",
                            value.id,
                            "idprovstate",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    provstatecode: "Seleziona una provincia",
                                });
                            },
                            function (options) {
                                fieldProvstate = vm.findField(
                                    vm.$refs.impianti.$refs.modalcomponent,
                                    "idprovstate"
                                );

                                if (fieldProvstate) {
                                    vm.$set(fieldProvstate, "filter_options", options);

                                    if (typeof fieldProvstate.value !== "undefined") {
                                        if (fieldProvstateValue !== null) {
                                            fieldProvstate.value = fieldProvstateValue;
                                        } else {
                                            fieldProvstate.value = JSON.parse(
                                                JSON.stringify(options[0])
                                            );
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.organigramma.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.organigramma.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.organigramma.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.organigramma.componentValuesloaded === null ||
                            this.$refs.organigramma.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.organigramma.$refs.modalcomponent,
                        "idregion"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldProvstate = null;
                let fieldProvstateValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldProvstate = this.findField(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idprovstate"
                        );
                        fieldProvstateValue = JSON.parse(
                            JSON.stringify(fieldProvstate.value)
                        );
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "provstates",
                            "idregion",
                            value.id,
                            "idprovstate",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    provstatecode: "Seleziona una provincia",
                                });
                            },
                            function (options) {
                                fieldProvstate = vm.findField(
                                    vm.$refs.organigramma.$refs.modalcomponent,
                                    "idprovstate"
                                );

                                if (fieldProvstate) {
                                    vm.$set(fieldProvstate, "filter_options", options);

                                    if (typeof fieldProvstate.value !== "undefined") {
                                        if (fieldProvstateValue !== null) {
                                            fieldProvstate.value = JSON.parse(
                                                JSON.stringify(fieldProvstateValue)
                                            );
                                        } else {
                                            fieldProvstate.value = JSON.parse(
                                                JSON.stringify(options[0])
                                            );
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.consiglio.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.consiglio.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.consiglio.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.consiglio.componentValuesloaded === null ||
                            this.$refs.consiglio.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.consiglio.$refs.modalcomponent,
                        "idprovstate"
                    );
                    let value = this.getFieldValue(field);

                    if (typeof value !== "undefined" && value && value.id)
                        return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldCity = null;
                let fieldCityValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldCity = this.findField(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "idcity"
                        );
                        fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.consiglio.$refs.modalcomponent,
                            "cities",
                            "idprovstate",
                            value.id,
                            "idcity",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    cityname: "Seleziona una città",
                                });
                            },
                            function (options) {
                                fieldCity = vm.findField(
                                    vm.$refs.consiglio.$refs.modalcomponent,
                                    "idcity"
                                );

                                if (fieldCity) {
                                    vm.$set(fieldCity, "filter_options", options);

                                    if (typeof fieldCity.value !== "undefined") {
                                        if (fieldCityValue !== null) {
                                            fieldCity.value = fieldCityValue;
                                        } else {
                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.impianti.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.impianti.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.impianti.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.impianti.componentValuesloaded === null ||
                            this.$refs.impianti.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.impianti.$refs.modalcomponent,
                        "idprovstate"
                    );
                    let value = this.getFieldValue(field);

                    if (typeof value !== "undefined" && value && value.id)
                        return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldCity = null;
                let fieldCityValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldCity = this.findField(
                            this.$refs.impianti.$refs.modalcomponent,
                            "idcity"
                        );
                        fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.impianti.$refs.modalcomponent,
                            "cities",
                            "idprovstate",
                            value.id,
                            "idcity",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    cityname: "Seleziona una città",
                                });
                            },
                            function (options) {
                                fieldCity = vm.findField(
                                    vm.$refs.impianti.$refs.modalcomponent,
                                    "idcity"
                                );

                                if (fieldCity) {
                                    vm.$set(fieldCity, "filter_options", options);

                                    if (typeof fieldCity.value !== "undefined") {
                                        if (fieldCityValue !== null) {
                                            fieldCity.value = fieldCityValue;
                                        } else {
                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.organigramma.componentFields;

                if (fields.length) {
                    if (
                        this.$refs.organigramma.$refs.modalcomponent.form_type === "edit" ||
                        this.$refs.organigramma.$refs.modalcomponent.form_type === "copy"
                    ) {
                        if (
                            this.$refs.organigramma.componentValuesloaded === null ||
                            this.$refs.organigramma.componentValuesloaded === false
                        ) {
                            return false;
                        }
                    }

                    let field = this.findField(
                        this.$refs.organigramma.$refs.modalcomponent,
                        "idprovstate"
                    );
                    let value = this.getFieldValue(field);

                    if (typeof value !== "undefined" && value && value.id)
                        return value.id;

                    return null;
                } else return null;
            },
            (newValue, oldValue) => {
                let doUpdate;
                let fieldCity = null;
                let fieldCityValue = null;

                if (newValue === false) {
                    doUpdate = false;
                } else {
                    doUpdate = true;
                    if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                    if (oldValue === false) {
                        fieldCity = this.findField(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "idcity"
                        );
                        fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                    }
                }

                if (doUpdate) {
                    let vm = this;
                    let value = { id: newValue };
                    if (value) {
                        vm.loadOptions(
                            this.$refs.organigramma.$refs.modalcomponent,
                            "cities",
                            "idprovstate",
                            value.id,
                            "idcity",
                            function (options) {
                                options.unshift({
                                    id: 0,
                                    cityname: "Seleziona una città",
                                });
                            },
                            function (options) {
                                fieldCity = vm.findField(
                                    vm.$refs.organigramma.$refs.modalcomponent,
                                    "idcity"
                                );

                                if (fieldCity) {
                                    vm.$set(fieldCity, "filter_options", options);

                                    if (typeof fieldCity.value !== "undefined") {
                                        if (fieldCityValue !== null) {
                                            fieldCity.value = fieldCityValue;
                                        } else {
                                            fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                        }
                                    }
                                }
                            }
                        );
                    }
                }
            }
        );
    },

    methods: {
        initPage() {
            let url =
                "/sportingclub/enrolled-user/load-options?type=massive&idsportingclub=" +
                this.$route.params.id;
            ApiService.query(url)
                .then((response) => {
                    this.optionsMembercards = response.data.membercards;
                    this.optionsRoleTypes = response.data.roleTypes;
                    this.optionsInsurances = response.data.insurances;
                    this.optionsActivities = response.data.activities;
                    this.optionsConi2Activities = response.data.coni2activities;
                })
                .catch((error) => {
                    console.log(error);
                });

            ApiService.query(
                "/enrolled-user/get-annualities?idsportingclub=" + this.$route.params.id
            )
                .then((response) => {
                    this.defaultidannualitytessere = response.data.idannuality;
                    this.idannualitytessere = response.data.idannuality;
                    this.defaultidannualitytesseramenti = response.data.idannuality;
                    this.idannualitytesseramenti = response.data.idannuality;
                    this.idannualityaffiliation = response.data.idannualityaffiliation;
                    this.can_migrate = response.data.can_migrate;
                    this.annualities = response.data.annualities;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        refreshTables() {
            if (typeof this.$refs.consiglio !== 'undefined') this.$refs.consiglio.refresh();
            if (typeof this.$refs.organigramma !== 'undefined') this.$refs.organigramma.refresh();
            if (typeof this.$refs.storicoorganigramma !== 'undefined') this.$refs.storicoorganigramma.refresh();
            if (typeof this.$refs.documenti !== 'undefined') this.$refs.documenti.refresh();
            if (typeof this.$refs.certificati !== 'undefined') this.$refs.certificati.refresh();
            if (typeof this.$refs.storicoaffiliazioni !== 'undefined') this.$refs.storicoaffiliazioni.refresh();
            if (typeof this.$refs.stocktessere !== 'undefined') this.$refs.stocktessere.refresh();
            if (typeof this.$refs.tesseramentiRicerca !== 'undefined') this.$refs.tesseramentiRicerca.refresh();
            if (typeof this.$refs.tesseramentiinbozza !== 'undefined') this.$refs.tesseramentiinbozza.refresh();
            if (typeof this.$refs.impianti !== 'undefined') this.$refs.impianti.refresh();
            if (typeof this.$refs.prezzitessere !== 'undefined') this.$refs.prezzitessere.refresh();
            if (typeof this.$refs.prezziassicurazioni !== 'undefined') this.$refs.prezziassicurazioni.refresh();
            if (typeof this.$refs.prezzicertificati !== 'undefined') this.$refs.prezzicertificati.refresh();
        },

        loadManageData() {
            let url = "/sportingclub/manage/" + this.$route.params.id;
            ApiService.query(url)
                .then((response) => {
                    this.sodalizio = response.data;
                    this.loaded = true;

                    this.nuovoStatoAffiliazione =
                        this.sodalizio.affiliations[0].affiliationstates.affiliationstate;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        closeModalOrganigramma() {
            this.$refs.consiglio.refresh();
            this.$refs.organigramma.refresh();
            this.$refs.storicoorganigramma.refresh();
        },

        closeModalFacilities() {
            this.$refs.impianti.refresh();
        },

        closeModalDocumenti() {
            this.$refs.documenti.refresh();
        },

        closeModalCertificati() {
            this.$refs.certificati.refresh();
        },

        closeModalTesseramenti() {
            this.$refs.tesseramentiinbozza.refresh();
            this.$refs.stocktessere.refresh();
            this.$refs.tesseramentiRicerca.refresh();
        },

        findField(obj, name) {
            if (
                typeof obj !== "undefined" &&
                obj &&
                typeof obj.fields !== "undefined" &&
                obj.fields
            ) {
                return obj.fields.find((item) => item.name === name);
            }

            return null;
        },

        getFieldValue(field) {
            if (field) {
                let value = typeof field.value !== "undefined" ? field.value : null;

                if (value !== null) {
                    return JSON.parse(JSON.stringify(value));
                }
            }

            return null;
        },

        setFieldVisible(obj, name, visible) {
            if (typeof obj.fields !== "undefined") {
                let fieldIndex = obj.fields.findIndex((item) => item.name === name);
                if (fieldIndex) {
                    if (
                        typeof obj.fields !== "undefined" &&
                        typeof obj.fields[fieldIndex] !== "undefined"
                    )
                        this.$set(obj.fields[fieldIndex], "is_visible_for_store", visible);
                    if (
                        typeof obj.fields !== "undefined" &&
                        typeof obj.fields[fieldIndex] !== "undefined"
                    )
                        this.$set(obj.fields[fieldIndex], "is_visible_for_update", visible);
                }
            }
        },

        setFieldAttribute(obj, name, attribute, value) {
            let fieldIndex = obj.fields.findIndex((item) => item.name === name);
            if (fieldIndex) {
                this.$set(obj.fields[fieldIndex], attribute, value);
            }
        },

        loadOptions(
            obj,
            type,
            parameterName,
            parameterValue,
            fieldName,
            callbackEmptyOption,
            callbackOptions = null
        ) {
            let url =
                "/sportingclub/load-options?type=" +
                type +
                "&" +
                parameterName +
                "=" +
                parameterValue;

            ApiService.query(url)
                .then((response) => {
                    let options = response.data;

                    if (typeof options === "undefined") options = [];

                    callbackEmptyOption(options);

                    if (callbackOptions !== null) {
                        callbackOptions(options);
                    } else {
                        this.setFieldAttribute(obj, fieldName, "filter_options", options);
                    }
                })
                .catch((error) => {
                    console.log(error);

                    let options = [];

                    callbackEmptyOption(options);

                    if (callbackOptions !== null) {
                        callbackOptions(options);
                    } else {
                        this.setFieldAttribute(obj, fieldName, "filter_options", options);
                    }
                });
        },

        getApiUrl() {
            return ApiService.getApiUrl();
        },

        linkClass(idx) {
            if (this.tabIndex === idx) {
                return [
                    "text-primary",
                    "border-top-primary",
                    "border-left-primary",
                    "border-right-primary",
                    "text-center",
                    "font-size-h5",
                ];
            } else {
                return ["bg-primary", "text-white", "font-size-h5"];
            }
        },

        linkClassTesseramenti(idx) {
            if (this.tabIndexTesseramenti === idx) {
                return [
                    "text-primary",
                    "border-top-primary",
                    "border-left-primary",
                    "border-right-primary",
                    "text-center",
                    "font-size-h5",
                ];
            } else {
                return ["bg-primary", "text-white", "font-size-h5"];
            }
        },

        processTesserati() {
            this.loading = true;

            let url =
                "/sportingclub/process-massive-enrolled-users?idsportingclub=" +
                this.$route.params.id;
            let data = { filename: this.filetesserati };

            this.fileErrors = {};
            this.tesseratiErrors = {};

            ApiService.post(url, data)
                .then((response) => {
                    this.tesserati = response.data;

                    this.loading = false;
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.$bvToast.toast(
                            "Si è verificato un errore nell'elaborazione del file: controllare i dati inseriti e riprovare.",
                            {
                                title: "Errore nell'elaborazione del file",
                                variant: "danger",
                                solid: true,
                            }
                        );

                        if (typeof response.data.fileerrors !== "undefined") {
                            this.fileErrors = response.data.fileerrors;
                        }

                        if (typeof response.data.errors !== "undefined") {
                            this.tesseratiErrors = response.data.errors;
                        }
                    } else {
                        console.log(response);
                    }

                    this.loading = false;
                });
        },

        saveTesserati() {
            this.loading = true;

            let url =
                "/sportingclub/save-massive-enrolled-users?idsportingclub=" +
                this.$route.params.id;
            let data = { tesserati: this.tesserati };

            this.tesseratiErrors = [];

            ApiService.post(url, data)
                .then((response) => {
                    if (response.data.status === "OK") {
                        this.$bvToast.toast("Tesserati salvati correttamente", {
                            title: "Tesserati salvati correttamente",
                            variant: "success",
                            solid: true,
                        });

                        this.tesserati = [];

                        this.loading = false;

                        this.closeModalTesseramenti();
                    }

                    if (response.data.status === "KO") {
                        this.$bvToast.toast(
                            "Si sono verificati degli errori nel salvataggio dei tesserati: controllare i dati inseriti e riprovare.",
                            {
                                title: "Errore nel salvataggio dei tesserati",
                                variant: "danger",
                                solid: true,
                            }
                        );

                        if (typeof response.data.tesseratiErrors !== "undefined") {
                            this.tesseratiErrors = response.data.tesseratiErrors;
                        }

                        this.loading = false;
                    }
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.$bvToast.toast(
                            "Si sono verificati degli errori nel salvataggio dei tesserati: controllare i dati inseriti e riprovare.",
                            {
                                title: "Errore nel salvataggio dei tesserati",
                                variant: "danger",
                                solid: true,
                            }
                        );

                        if (typeof response.data.tesseratiErrors !== "undefined") {
                            this.tesseratiErrors = response.data.tesseratiErrors;
                        }
                    } else {
                        console.log(response);
                    }

                    this.loading = false;
                });
        },

        saveMigraTesserati() {
            this.loading = true;

            let url =
                "/enrolled-user/save-migrate-massive?idsportingclub=" +
                this.$route.params.id;
            let data = { tesserati: this.migraTesserati };

            this.migraTesseratiErrors = [];

            ApiService.post(url, data)
                .then((response) => {
                    if (response.data.status === "OK") {
                        this.$bvToast.toast("Tesserati salvati correttamente", {
                            title: "Tesserati salvati correttamente",
                            variant: "success",
                            solid: true,
                        });

                        this.migraTesserati = [];

                        this.loading = false;

                        this.closeMigraTesseratiModal();
                    }

                    if (response.data.status === "KO") {
                        this.$bvToast.toast(
                            "Si sono verificati degli errori nel salvataggio dei tesserati: controllare i dati inseriti e riprovare.",
                            {
                                title: "Errore nel salvataggio dei tesserati",
                                variant: "danger",
                                solid: true,
                            }
                        );

                        if (typeof response.data.migraTesseratiErrors !== "undefined") {
                            this.migraTesseratiErrors = response.data.migraTesseratiErrors;
                        }

                        this.loading = false;
                    }
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.$bvToast.toast(
                            "Si sono verificati degli errori nel salvataggio dei tesserati: controllare i dati inseriti e riprovare.",
                            {
                                title: "Errore nel salvataggio dei tesserati",
                                variant: "danger",
                                solid: true,
                            }
                        );

                        if (typeof response.data.migraTesseratiErrors !== "undefined") {
                            this.migraTesseratiErrors = response.data.migraTesseratiErrors;
                        }
                    } else {
                        console.log(response);
                    }

                    this.loading = false;
                });
        },

        closeMigraTesseratiModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["migraTesseratiModal"].hide();
            }, 200);
        },

        emptyTesseratoForm() {
            this.loading = true;

            this.$refs.tesseratoForm.emptyValues();

            this.tesseratoFormSaveUrl = "/sportingclub/enrolled-user-draft";
            let vm = this;
            setTimeout(() => {
                vm.$refs.tesseratoForm.getOptions();

                vm.loading = false;
            }, 100);

            this.$refs.stocktessere.refresh();
        },

        saveDraft() {
            this.loading = true;

            this.tesseratoFormSaveUrl = "/sportingclub/enrolled-user-draft";
            let vm = this;
            setTimeout(() => {
                vm.$refs.tesseratoForm.doAction("save");

                vm.loading = false;
            }, 100);
        },

        saveDirect() {
            this.loading = true;

            this.tesseratoFormSaveUrl = "/sportingclub/enrolled-user";
            let vm = this;
            setTimeout(() => {
                vm.$refs.tesseratoForm.doAction("save");

                vm.loading = false;
            }, 100);
        },

        afterSaveDraft() {
            this.emptyTesseratoForm();

            if (this.tesseratoFormSaveUrl === "/sportingclub/enrolled-user-draft") {
                this.$refs.tesseramentiinbozza.refresh();
                this.$refs.tesseramentiRicerca.refresh();
            } else if (this.tesseratoFormSaveUrl === "/sportingclub/enrolled-user") {
                this.$refs.tesseramentiRicerca.refresh();
            }
        },

        removeTesserato(indexTesserato) {
            if (typeof this.tesseratiErrors[indexTesserato] !== "undefined")
                this.tesseratiErrors.splice(indexTesserato, 1);
            this.tesserati.splice(indexTesserato, 1);
        },

        removeMigraTesserato(indexTesserato) {
            if (typeof this.migraTesseratiErrors[indexTesserato] !== "undefined")
                this.migraTesseratiErrors.splice(indexTesserato, 1);
            this.migraTesserati.splice(indexTesserato, 1);
        },

        newTesseratoTop() {
            this.tesserati.unshift({
                idmembercard: null,
                surname: "",
                name: "",
                taxcode: "",
                idroletype: null,
                email: "",
                phonenumber: "",
                idinsurrance: null,
                coni2activities: [],
                activities: [],
            });
        },

        newTesseratoBottom() {
            this.tesserati.push({
                idmembercard: null,
                surname: "",
                name: "",
                taxcode: "",
                idroletype: null,
                email: "",
                phonenumber: "",
                idinsurrance: null,
                coni2activities: [],
                activities: [],
            });
        },

        closeModalPrezziTessere() {
            this.$refs.prezzitessere.refresh();
        },

        closeModalPrezziAssicurazioni() {
            this.$refs.prezziassicurazioni.refresh();
        },

        closeModalPrezziCertificati() {
            this.$refs.prezzicertificati.refresh();
        },

        cambiaStatoAffiliazione() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["cambiaStatoAffiliazioneModal"].show();
            }, 200);
        },

        showCambiaDatiAffiliazioneModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["cambiaDatiAffiliazioneModal"].show();
            }, 200);
        },

        showInviaDatiAffiliazioneModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["inviaDatiAffiliazioneModal"].show();
            }, 200);
        },

        showDeleteEnrolledUserModal(id) {
            this.iddeleteenrolleduser = id;

            let vm = this;
            setTimeout(function () {
                vm.$refs["deleteEnrolledUserModal"].show();
            }, 200);
        },

        migraTesserato(id) {
            this.iddeleteenrolleduser = id;

            let vm = this;
            setTimeout(function () {
                vm.$refs["migrateEnrolledUserModal"].show();
            }, 200);
        },

        closeMigrateEnrolledUserModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["migrateEnrolledUserModal"].hide();
            }, 200);
        },

        hideModalAffiliazione() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["cambiaStatoAffiliazioneModal"].hide();
            }, 200);
        },

        closeDeleteEnrolledUserModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["deleteEnrolledUserModal"].hide();
            }, 200);
        },

        confermaCambiaStatoAffiliazione() {
            this.loading = true;

            let url =
                "/sportingclub/change-affiliationstate?idsportingclub=" +
                this.$route.params.id;
            let data = { affiliationstate: this.nuovoStatoAffiliazione };

            ApiService.post(url, data)
                .then((response) => {
                    if (response.data.status === "OK") {
                        this.$bvToast.toast("Stato affiliazione cambiato correttamente", {
                            title: "Stato affiliazione cambiato correttamente",
                            variant: "success",
                            solid: true,
                        });

                        this.sodalizio.affiliations[0].affiliationstates.affiliationstate =
                            this.nuovoStatoAffiliazione;

                        let vm = this;
                        setTimeout(function () {
                            vm.$refs["cambiaStatoAffiliazioneModal"].hide();
                        }, 200);
                    }

                    if (response.data.status === "KO") {
                        let message = "";
                        if (typeof response.data.message !== "undefined")
                            message = response.data.message;
                        this.$bvToast.toast(
                            "Si sono verificati degli errori nel cambio di stato dell'affiliazione. " +
                            message,
                            {
                                title: "Errore nel cambio di stato dell'affiliazione.",
                                variant: "danger",
                                solid: true,
                            }
                        );

                        let vm = this;
                        setTimeout(function () {
                            vm.$refs["cambiaStatoAffiliazioneModal"].hide();
                        }, 200);
                    }

                    this.loading = false;
                })
                .catch(({ response }) => {
                    console.log(response);

                    let vm = this;
                    setTimeout(function () {
                        vm.$refs["cambiaStatoAffiliazioneModal"].hide();
                    }, 200);

                    this.loading = false;
                });
        },

        aggiornaCambiaDatiAffiliazione() {
            this.loadManageData();
            this.initPage();
            this.refreshTables();
            this.closeCambiaDatiAffiliazioneModal();
        },

        aggiornaMigrateForm() {
            this.closeMigrateEnrolledUserModal();
        },

        closeCambiaDatiAffiliazioneModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["cambiaDatiAffiliazioneModal"].hide();
            }, 200);
        },

        aggiornaInviaDatiAffiliazione() {
            this.loadManageData();
            this.initPage();
            this.refreshTables();
            this.closeInviaDatiAffiliazioneModal();
        },

        closeInviaDatiAffiliazioneModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["inviaDatiAffiliazioneModal"].hide();
            }, 200);
        },

        attivaTesserato(id) {
            Swal.fire({
                title: "Conferma attivazione tesserato",
                text: "Sei sicuro di voler attivare il tesserato?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = "/sportingclub/activate-enrolled-user";

                    ApiService.post(url, { idenrolleduser: id })
                        .then((response) => {
                            if (response.data.status === "OK") {
                                this.$bvToast.toast(
                                    "Il tesserato �� stato attivato correttamente",
                                    {
                                        title: "Tesserato attivato corretamente",
                                        variant: "success",
                                        solid: true,
                                    }
                                );

                                this.$refs.tesseramentiinbozza.refresh();
                                this.$refs.tesseramentiRicerca.refresh();
                            }

                            if (response.data.status === "KO") {
                                this.$bvToast.toast(
                                    "Si è verificato un errore nell'attivazione del tesserato.",
                                    {
                                        title: "Errore nell'attivazione del tesserato.",
                                        variant: "danger",
                                        solid: true,
                                    }
                                );
                            }

                            this.loading = false;
                        })
                        .catch(({ response }) => {
                            this.$bvToast.toast(
                                "Si è verificato un errore nell'attivazione del tesserato.",
                                {
                                    title: "Errore nell'attivazione del tesserato",
                                    variant: "danger",
                                    solid: true,
                                }
                            );
                            console.log(response);

                            this.loading = false;
                        });
                }
            });
        },

        dateFromIsoToIt(isodate) {
            let date = new Date(isodate);

            let month = date.getMonth() + 1;
            if (month < 10) month = "0" + month;

            let day = date.getDate();
            if (day < 10) day = "0" + day;

            return day + "/" + month + "/" + date.getFullYear();
        },

        aggiornaComitatoCorrente() {
            let fieldHeading1 = this.$refs.cambiaDatiAffiliazioneForm.fields.find(
                function (item) {
                    return item.name === "heading1";
                }
            );

            let fieldComitato = this.$refs.cambiaDatiAffiliazioneForm.fields.find(
                function (item) {
                    return item.name === "idcommittee";
                }
            );

            if (typeof fieldComitato !== "undefined") {
                fieldHeading1.message += fieldComitato.value.displayname;
            }
        },

        confirmDeleteEnrolledUser(id) {
            Swal.fire({
                title: "Conferma eliminazione tesserato",
                text: "Sei sicuro di voler eliminare il tesserato?",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
                confirmButtonColor: "#F64E60",

                input: "checkbox",
                inputValue: 1,
                inputPlaceholder: "Restituisci la tessera al sodalizio",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = "/sportingclub/enrolled-user/destroy";

                    ApiService.post(url, { id: id, restore_card: result.value })
                        .then((response) => {
                            if (response.data.status === "OK") {
                                this.$bvToast.toast(
                                    "Il tesserato è stato eliminato correttamente",
                                    {
                                        title: "Tesserato eliminato corretamente",
                                        variant: "success",
                                        solid: true,
                                    }
                                );

                                this.closeDeleteEnrolledUserModal();
                                this.$refs.tesseramentiinbozza.refresh();
                                this.$refs.tesseramentiRicerca.refresh();
                                this.$refs.tesseratoForm.getOptions();
                            }

                            if (response.data.status === "KO") {
                                this.$bvToast.toast(
                                    "Si è verificato un errore nell'eliminazione del tesserato.",
                                    {
                                        title: "Errore nell'eliminazione del tesserato.",
                                        variant: "danger",
                                        solid: true,
                                    }
                                );
                            }

                            this.loading = false;
                        })
                        .catch(({ response }) => {
                            this.$bvToast.toast(
                                "Si è verificato un errore nell'eliminazione del tesserato.",
                                {
                                    title: "Errore nell'eliminazione del tesserato",
                                    variant: "danger",
                                    solid: true,
                                }
                            );
                            console.log(response);

                            this.loading = false;
                        });
                }
            });
        },

        confirmMigrateEnrolledUser(id) {
            Swal.fire({
                title: "Conferma migrazione tesserato",
                text: "Sei sicuro di voler migrare il tesserato?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
                confirmButtonColor: "#19C5BD",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let vm = this;
                    setTimeout(() => {
                        vm.$refs.migrateEnrolledUserForm.doAction("save");

                        vm.loading = false;
                    }, 100);
                }
            });
        },

        aggiornaSodalizio() { },

        showHandleInsuranceEnrolledUser(id) {
            this.idinsuranceenrolleduser = id;

            let vm = this;
            setTimeout(function () {
                vm.$refs["handleInsuranceEnrolledUser"].show();
            }, 200);
        },

        hideHandleInsuranceEnrolledUser() {
            let vm = this;
            setTimeout(function () {
                vm.$refs["handleInsuranceEnrolledUser"].hide();
            }, 200);
        },

        inserisciInviaTesseratiBozza() {
            Swal.fire({
                title: "Conferma Inserimento e Invio Tesserati in Bozza",
                text: "Sei sicuro di voler inserire e inviare i Tesserati selezionati?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs.tesseramentiinbozza.items) {
                        if (this.$refs.tesseramentiinbozza.items[i].checked)
                            values.push({
                                idenrolleduser: this.$refs.tesseramentiinbozza.items[i].id,
                            });
                    }

                    if (values.length) {
                        let url = "/sportingclub/save-draft-enrolled-users";
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === "OK") {
                                    this.$bvToast.toast(
                                        "Tesserati salvati e inviati correttamente",
                                        {
                                            title: "Tesserati salvati e inviati correttamente",
                                            variant: "success",
                                            solid: true,
                                        }
                                    );

                                    this.$refs.tesseramentiinbozza.refresh();
                                    this.$refs.tesseramentiRicerca.refresh();
                                }

                                if (response.data.status === "KO") {
                                    this.$bvToast.toast(
                                        "Si sono verificati degli errori nel salvataggio e invio dei tesserati.",
                                        {
                                            title: "Errore nel salvataggio e invio dei tesserati",
                                            variant: "danger",
                                            solid: true,
                                        }
                                    );
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    } else {
                        Swal.fire({
                            title: "Nessun Tesserato selezionato",
                            text: "Per effettuare il salvataggio e invio selezionare almeno un Tesserato",
                            icon: "warning",
                            showConfirmButton: true,
                            confirmButtonText: "Ok",
                        });
                    }

                    this.loading = false;
                }
            });
        },

        migrateTesserati() {
            Swal.fire({
                title: "Conferma Migrazione Tesserati",
                text: "Sei sicuro di voler migrare i Tesserati selezionati?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs.tesseramentiRicerca.items) {
                        if (this.$refs.tesseramentiRicerca.items[i].checked)
                            values.push({
                                idenrolleduser: this.$refs.tesseramentiRicerca.items[i].id,
                            });
                    }

                    if (values.length) {
                        let url =
                            "/enrolled-user/migrate-massive?idsportingclub=" +
                            this.$route.params.id;
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === "OK") {
                                    this.migraTesserati = response.data.tesserati;

                                    let vm = this;
                                    setTimeout(function () {
                                        vm.$refs["migraTesseratiModal"].show();
                                    }, 200);
                                }

                                if (response.data.status === "KO") {
                                    this.$bvToast.toast(
                                        "Si sono verificati degli errori nella migrazione massiva dei Tesserati.",
                                        {
                                            title: "Errore nella migrazione massiva dei tesserati",
                                            variant: "danger",
                                            solid: true,
                                        }
                                    );
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        Swal.fire({
                            title: "Nessun Tesserato selezionato",
                            text: "Per effettuare la migrazione selezionare almeno un Tesserato",
                            icon: "warning",
                            showConfirmButton: true,
                            confirmButtonText: "Ok",
                        });
                    }

                    this.loading = false;
                }
            });
        },

        activateTesserati() {
            Swal.fire({
                title: "Conferma Attivazione Tesserati",
                text: "Sei sicuro di voler attivare i Tesserati selezionati?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs.tesseramentiRicerca.items) {
                        if (this.$refs.tesseramentiRicerca.items[i].checked)
                            values.push({
                                idenrolleduser: this.$refs.tesseramentiRicerca.items[i].id,
                            });
                    }

                    if (values.length) {
                        let url =
                            "/enrolled-user/activate-massive?idsportingclub=" +
                            this.$route.params.id;
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === "OK") {
                                    this.$bvToast.toast("Tesserati attivati correttamente", {
                                        title: "Tesserati attivati correttamente",
                                        variant: "success",
                                        solid: true,
                                    });

                                    this.$refs.tesseramentiRicerca.refresh();

                                    this.loading = false;
                                }

                                if (response.data.status === "KO") {
                                    this.$bvToast.toast(
                                        "Si sono verificati degli errori nell'attivazione massiva dei Tesserati.",
                                        {
                                            title: "Errore nell'attivazione massiva dei tesserati",
                                            variant: "danger",
                                            solid: true,
                                        }
                                    );

                                    this.loading = false;
                                }
                            })
                            .catch((error) => {
                                this.loading = false;

                                console.log(error);
                            });
                    } else {
                        Swal.fire({
                            title: "Nessun Tesserato selezionato",
                            text: "Per effettuare l'attivazione massiva selezionare almeno un Tesserato",
                            icon: "warning",
                            showConfirmButton: true,
                            confirmButtonText: "Ok",
                        });
                    }

                    this.loading = false;
                }
            });
        },

        sendEmailApp() {
            Swal.fire({
                title: "Conferma Invio E-mail di Attivazione App",
                text: "Sei sicuro di voler nviare l'e-mail di attivazione dell'App per i Tesserati selezionati?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs.tesseramentiRicerca.items) {
                        if (this.$refs.tesseramentiRicerca.items[i].checked)
                            values.push({
                                idenrolleduser: this.$refs.tesseramentiRicerca.items[i].id,
                            });
                    }

                    if (values.length) {
                        let url =
                            "/enrolled-user/email-app-massive?idsportingclub=" +
                            this.$route.params.id;
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === "OK") {
                                    this.$bvToast.toast(
                                        "E-mail di Attivazione App inviata correttamente ai Tesserati selezionati",
                                        {
                                            title: "E-mail di attivazione inviata correttamente",
                                            variant: "success",
                                            solid: true,
                                        }
                                    );

                                    this.$refs.tesseramentiRicerca.refresh();

                                    this.loading = false;
                                }

                                if (response.data.status === "KO") {
                                    this.$bvToast.toast(
                                        "Si sono verificati degli errori nell'invio dell'E-mail di attivazione dell'App per i Tesserati selezionati.",
                                        {
                                            title: "Errori nell'invio dell'E-mail di attivazione",
                                            variant: "danger",
                                            solid: true,
                                        }
                                    );

                                    this.loading = false;
                                }
                            })
                            .catch((error) => {
                                this.loading = false;

                                console.log(error);
                            });
                    } else {
                        Swal.fire({
                            title: "Nessun Tesserato selezionato",
                            text: "Per effettuare l'invio dell'E-mail di attivazione dell'App selezionare almeno un Tesserato",
                            icon: "warning",
                            showConfirmButton: true,
                            confirmButtonText: "Ok",
                        });
                    }

                    this.loading = false;
                }
            });
        },
    },
};
</script>

<style>
.border-bluenavy .card-header {
    border-bottom: 1px solid #143d67;
}

.modal .modal-fullscreen.modal-dialog {
    width: 90vw;
    height: 90vh;
    max-width: none;
}

.modal .modal-fullscreen .modal-content {
    height: auto;
    height: 90vh;
}

.modal .modal-fullscreen .modal-body {
    overflow-y: auto;
}
</style>
